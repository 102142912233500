import React, { useEffect, useState } from "react";
// import "./gettestdetails.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Layout from "../layout";
import LoaderSpiner from "../loader";
import SecButton from "../secButton";

function GetTestDetails() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [getAllAns, setgetAllAns] = useState([]);
  const [viewallDetails, setviewallDetails] = useState([]);
  const [resultopen, setResultOpen] = useState(false);
  const [detailopen, setDetailOpen] = useState(false);
  const [empalldata, setEmpAllData] = useState([]);
  const [getdate, setGetDate] = useState("");
  const [gettime, setGetTime] = useState("");
  const [interviewerfirst, setInterviewerFirst] = useState();
  const [interviewersecond, setInterviewerSecond] = useState();
  const [today, setToday] = useState("");
  const [loading, setLoading] = useState(false);

  const getAnswerData = async () => {
    setLoading(true)
    try {
      const response = await axios.post(`${apiUrl}/results/getAllRightAnsUsers`);
      const mydata = response.data.userResults
      setgetAllAns(mydata);
      // console.log('user data is attendExamUser getAnswerdata >>', mydata)
      setLoading(false)
    } catch (error) {
      console.error("getAllRightAns error", error);
      setLoading(false)
    }
  };

  useEffect(() => {
    getAnswerData();
    getAllEmpData();
  }, []);

  const handleviewDetails = async (id) => {
    setLoading(true)
    setResultOpen(true);
    try {
      const response = await axios.post(`${apiUrl}/results/getAllRightAns`, {
        user_id: id,
      });
      const responseData = [response.data];
      setviewallDetails(responseData);
      setLoading(false)
      // console.log('view id get all details getAllRightAns >>>>', responseData)
    } catch (error) {
      console.error("get view details error", error);
      setLoading(false)
    }
  };

  const handleDeleteDetails = async (id) => {
    try {
      const response = await axios.delete(`${apiUrl}/results/delete/${id}`);
      if (response.status === 200) {
        getAnswerData();
        toast.success("Delete candiadte result Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        // console.log("delete id error");
      }
    } catch (error) {
      console.error("get view details error", error);
    }
  };

  const handleviewSendMail = async (Id) => {
    setLoading(true)
    await handleviewDetails(Id);
    setResultOpen(false);
    setDetailOpen(true);
    setLoading(false)
  };

  const getAllEmpData = async () => {
    setLoading(true)
    try {
      const res = await axios.get(`${apiUrl}/employeeurl/getemployees`);
      const resdata = res.data;
      setEmpAllData(resdata.data);
      setLoading(false)
      // console.log('res getemployees ens >>>>>>>>>>>', res)
    } catch (error) {
      console.error("ens all employee data", error);
      setLoading(false)
    }
  };

  const handleInterviewerFirstChange = (e) => {
    const selectedInterviewer = e.target.value;
    const selectedItem = empalldata.find(
      (item) => item.name === selectedInterviewer
    );
    setInterviewerFirst(selectedItem || null);
  };

  const handleInterviewerSecondChange = (e) => {
    const selectedInterviewer = e.target.value;
    const selectedItem = empalldata.find(
      (item) => item.name === selectedInterviewer
    );
    setInterviewerSecond(selectedItem || null);
  };

  const handleSubmitInterviewDetails = async (Id) => {
    setLoading(true);
    try {
      const postdata = {
        viewallDetails,
        interviewerfirst,
        interviewersecond,
        getdate,
        gettime,
      };
      // console.log("my submit all data mail >>>>>>>>>>>>>>>>", postdata);
      if (interviewerfirst && interviewersecond) {
        const response = await axios.post(
          `${apiUrl}/mailUrl/approveToSecondRound`,
          postdata
        );
        // console.log("approveToSecondRound response", response);
        if (response.status === 200) {
          setDetailOpen(false);
          setLoading(false);
          toast.success('Interview Scheddule Successfully', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } else {
        // console.error("interviewerfirst and interviewersecond are not set");
      }
    } catch (error) {
      console.error("api for approveToSecondRound", error);
      setLoading(false)
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const formattedToday = currentDate.toISOString().split("T")[0];
    setToday(formattedToday);
  }, []);

  const handleDateChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "date") {
      const selectedDate = new Date(value);
      const formattedDate = selectedDate.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      setGetDate(formattedDate);
    } else if (name === "time") {
      const timeForFormatting = new Date(`2000-01-01T${value}`);
      const formattedTime = timeForFormatting.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      setGetTime(formattedTime);
    }
  };

  return (
    <div>
      {loading === true ? <LoaderSpiner text="Loading..." desc="please don't refresh or back while data is loading" /> : null}
      <Layout setDropdown={true}>
        <div className="border-b border-gray-200 h-[480px] overflow-y-scroll">
          <table className="min-w-full  divide-y divide-gray-200">
            <thead className="bg-gray-50 sticky top-0">
              <tr  >
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                >
                  Candidate Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                >
                  Attempt
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody
              className="bg-white divide-y divide-gray-200 cursor-pointer overflow-x-scroll max-h-[300px]"
            >
              {console.log('getAllAns >>>>>>>>>>>>>>>>', getAllAns)}
              {
                getAllAns.map((item, id) => {
                  console.log('get all text data is >>>>>>>>>>>>>>>', item)
                  const formattedDate =
                    getAllAns && item.usersDetails[0]?.updatedAt
                      ? new Date(item.usersDetails[0].updatedAt).toLocaleDateString()
                      : 'N/A';
                  const passPercentage = (item.rightAnsCountData / item.totalSetQuestions) * 100;
                  return (
                    <React.Fragment key={id}>
                      <tr>
                        <td className="text-center py-3">{formattedDate}</td>
                        <td className="text-center py-3">
                          {item.usersDetails[0]?.firstName} {item.usersDetails[0]?.lastName}
                        </td>
                        <td className="text-center py-3">{item.usersDetails[0]?.email}</td>
                        <td className="text-center py-3">
                          {item.rightAnsCountData}/{item.totalSetQuestions}
                        </td>
                        <td className="text-center py-3">
                          {passPercentage >= 33 ? "PASS" : "FAIL"}
                        </td>
                        <td className="text-center w-36">
                          <div className="flex items-center justify-between py-3 px-3">
                            <button className="font-semibold text-lg bg-green-600 p-1 rounded text-white" onClick={() => handleviewDetails(item.usersDetails[0]?.id)}>
                              <svg
                                enableBackground="new 0 0 32 32"
                                height="22"
                                id="svg2"
                                version="1.1"
                                viewBox="0 0 32 32"
                                width="22"
                                xmlSpace="preserve"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnscc="http://creativecommons.org/ns#"
                                xmlnsdc="http://purl.org/dc/elements/1.1/"
                                xmlnsinkscape="http://www.inkscape.org/namespaces/inkscape"
                                xmlnsrdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                                xmlnssodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                                xmlnssvg="http://www.w3.org/2000/svg"
                              >
                                <g id="background">
                                  <rect fill="none" height="22" width="22" />
                                </g>
                                <g id="view">
                                  <circle cx="16" cy="16" r="6" />
                                  <path d="M16,6C6,6,0,15.938,0,15.938S6,26,16,26s16-10,16-10S26,6,16,6z M16,24c-8.75,0-13.5-8-13.5-8S7.25,8,16,8s13.5,8,13.5,8   S24.75,24,16,24z" />
                                </g>
                              </svg>
                            </button>

                            <button className="font-semibold text-lg rounded-md bg-green-600 p-1 rounded text-white" onClick={() => handleviewSendMail(item.usersDetails[0]?.id)}>
                              <svg
                                width="22"
                                viewBox="0 0 16 16"
                              >
                                <g fillRule="evenodd">
                                  <path d="M14 14H2C1.448 14 1 13.551 1 13V5.833L7.765 9.441C7.838 9.48 7.919 9.5 8 9.5C8.081 9.5 8.162 9.48 8.235 9.441L15 5.833V13C15 13.551 14.552 14 14 14ZM2 2H14C14.552 2 15 2.449 15 3V4.7L8 8.433L1 4.7V3C1 2.449 1.448 2 2 2ZM14 1H2C0.897 1 0 1.897 0 3V13C0 14.103 0.897 15 2 15H14C15.103 15 16 14.103 16 13V3C16 1.897 15.103 1 14 1Z"></path>
                                </g>
                              </svg>
                            </button>

                            <button className=" font-semibold text-lg bg-red-600 p-1 rounded text-white" onClick={() => handleDeleteDetails(item.usersDetails[0]?.id)}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="22" stroke="currentColor" viewBox="0 0 32 32" fill="white" id="delete"><path d="M24.2,12.193,23.8,24.3a3.988,3.988,0,0,1-4,3.857H12.2a3.988,3.988,0,0,1-4-3.853L7.8,12.193a1,1,0,0,1,2-.066l.4,12.11a2,2,0,0,0,2,1.923h7.6a2,2,0,0,0,2-1.927l.4-12.106a1,1,0,0,1,2,.066Zm1.323-4.029a1,1,0,0,1-1,1H7.478a1,1,0,0,1,0-2h3.1a1.276,1.276,0,0,0,1.273-1.148,2.991,2.991,0,0,1,2.984-2.694h2.33a2.991,2.991,0,0,1,2.984,2.694,1.276,1.276,0,0,0,1.273,1.148h3.1A1,1,0,0,1,25.522,8.164Zm-11.936-1h4.828a3.3,3.3,0,0,1-.255-.944,1,1,0,0,0-.994-.9h-2.33a1,1,0,0,0-.994.9A3.3,3.3,0,0,1,13.586,7.164Zm1.007,15.151V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Zm4.814,0V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Z"></path></svg>
                              <ToastContainer />
                            </button>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        {resultopen ?
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-md w-[550px]">
              {(viewallDetails).map((view, index) => {
                const userDetails = view.usersDetails[0];
                return (
                  <div className="answer_sheet_pop_conatainer" key={index}>
                    <div className="m-auto w-full">
                      <p className="text-xl text-center font-bold mb-5">Candidate Answer Sheet</p>
                      <div className="flex justify-between">
                        <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Name :</label>
                        <p className="block text-gray-700 text-base font-normal font-bold">
                          {userDetails?.firstName}&nbsp;{userDetails?.lastName}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Email :</label>
                        <p className="block text-gray-700 text-base font-normal font-bold">
                          {userDetails?.email}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Phone no. :</label>
                        <p className="block text-gray-700 text-base font-normal font-bold">
                          {userDetails?.mobileNumber}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Attend Questions :</label>
                        <p className="block text-gray-700 text-base font-normal font-bold">{view.attendQuesData}</p>
                      </div>
                      <div className="flex justify-between">
                        <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Right Answer :</label>
                        <p className="block text-gray-700 text-base font-normal font-bold">{view.rightAnsCountData}</p>
                      </div>
                      <div className="flex justify-between">
                        <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Wrong Answer :</label>
                        <p className="block text-gray-700 text-base font-normal font-bold">{view.wrongAnsCountData}</p>
                      </div>
                      <div className="flex justify-between">
                        <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Total Question :</label>
                        <p className="block text-gray-700 text-base font-normal font-bold">{view.totalSetQuestions}</p>
                      </div>
                      <div className="mt-2 border-gray-200 h-[150px] overflow-y-scroll">
                        <table className="min-w-full  divide-y divide-gray-200">
                          <thead className="bg-gray-50 sticky top-0 border border-1">
                            <tr  >
                              <th
                                scope="col"
                                className=" py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase border border-1"
                              >
                                Technology
                              </th>
                              <th
                                scope="col"
                                className=" py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase border border-1"
                              >
                                Total Question
                              </th>
                              <th
                                scope="col"
                                className=" py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase border border-1"
                              >
                                Attend Questions
                              </th>
                              <th
                                scope="col"
                                className=" py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase border border-1"
                              >
                                Right Answer
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            className="bg-white divide-y divide-gray-200 cursor-pointer overflow-x-scroll max-h-[260px]"
                          >{view.technologyCountsAllQues && view.technologyCountsAllQues.map((tech, techIndex) => {
                            return (
                              <tr key={techIndex} className="transition-all hover:bg-gray-100 hover:shadow-lg text-center">
                                <td className="px-6 py-2 whitespace-nowrap border border-1" >
                                  <div className="text-sm text-gray-900">
                                    {tech.technology}
                                  </div>
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap border border-1">
                                  <div className="text-sm text-gray-900">
                                    {view.technologyCountsAllQues.find((item) => item.technology === tech.technology)?.count || 0}
                                  </div>
                                </td>

                                <td className="px-6 py-2 whitespace-nowrap border border-1">
                                  <div className="text-sm text-gray-900">
                                    {view.technologyCountsAttendQues.find((item) => item.technology === tech.technology)?.count || 0}
                                  </div>
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap border border-1">
                                  <div className="text-sm text-gray-900">
                                    {view.technologyCountsRightAns.find((item) => item.technology === tech.technology)?.count || 0}
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex justify-between my-4">
                        <div className="mail_send_btn_content">
                          <SecButton text="Close X" className="bg-red-700" onClick={() => setResultOpen(false)} />
                        </div>
                        <div className="mail_send_btn_content">
                          <SecButton text="Send Answer Sheet" className="bg-green-700 hover:bg-green-500" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          : null}
        {/* --------------------- */}
        {
          detailopen ?
            viewallDetails.map((item, index) => {
              const userDetails = item.usersDetails[0];
              return (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50" key={index}>
                  <div className="bg-white p-8 rounded-md w-[550px]">
                    <p className="text-xl text-center font-bold mb-6">Candidate interview Details</p>
                    <div className="flex justify-between">
                      <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Candidate Name :</label>
                      <p className="block text-gray-700 text-base font-normal font-bold">
                        {userDetails?.firstName}&nbsp;{userDetails?.lastName}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Email :</label>
                      <p className="block text-gray-700 text-base font-normal font-bold">{userDetails?.email}</p>
                    </div>
                    <div className="flex justify-between">
                      <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Mobile No. :</label>
                      <p className="block text-gray-700 text-base font-normal font-bold">{userDetails?.mobileNumber}</p>
                    </div>
                    <div className="flex justify-between">
                      <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Reference :</label>
                      <p className="block text-gray-700 text-base font-normal font-bold">{userDetails?.reference_by ? userDetails.reference_by : 'No Reference'}</p>
                    </div>
                    <div className="flex justify-between">
                      <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Experience :</label>
                      <p className="block text-gray-700 text-base font-normal font-bold">
                        {userDetails?.experience_year ? userDetails.experience_year : 'No Experience'}
                      </p>
                    </div>
                    <div className="flex justify-between mb-2">
                      <div>
                        <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">First Interview</label>
                        <select
                          className="border border-gray-500 p-2 rounded-md w-[230px]"
                          onChange={(e) => handleInterviewerFirstChange(e)}
                          required
                        >
                          <option value="">--Select Interview 1--</option>
                          {empalldata.map((item, index) => (
                            <option key={index} value={interviewerfirst} required>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div >
                        <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Second Interview</label>
                        <select
                          className="border border-gray-500 p-2 rounded-md w-[230px]"
                          onChange={(e) => handleInterviewerSecondChange(e)}
                        >
                          <option value="">--Select Interview 2--</option>
                          {empalldata.map((item, index) => (
                            <option key={index} value={interviewersecond}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div >
                        <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Date</label>
                        <input
                          type="date"
                          className="border border-gray-500 p-2 rounded-md w-[230px]"
                          value={getdate.date}
                          name="date"
                          min={today}
                          onChange={handleDateChange}
                          required
                        />
                      </div>
                      <div >
                        <label className="block text-gray-700 text-lg font-bold mb-2 font-medium">Time</label>
                        <input
                          type="time"
                          className="border border-gray-500 p-2 rounded-md w-[230px]"
                          value={gettime.time}
                          name="time"
                          onChange={handleDateChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex justify-between mt-4">
                      <div className="mail_send_btn_content">
                        <SecButton text="Close X" onClick={() => setDetailOpen(false)} className="bg-red-700" />
                      </div>
                      <div className="mail_send_btn_content">
                        <SecButton text="Send" onClick={() => handleSubmitInterviewDetails(item.id)} className="bg-green-700 hover:bg-green-500" />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
            : null
        }
      </Layout >
    </div >
  );
}

export default GetTestDetails;
