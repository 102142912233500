import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import SecButton from "../components/secButton";
import LoaderSpiner from "../components/loader";

const CandidateRegistration = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const initialFormData = {
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        jobType: "",
        applyFor: "",
        reference_by: "",
        experience_year: "",
    };

    const navigate = useNavigate()
    const [formData, setformData] = useState(initialFormData);
    const [ApplyTech, setApplyTech] = useState([]);
    const [ApplyTechID, setApplyTechID] = useState('')
    const [refer_ID, setrefer_ID] = useState('')
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(false)

    // console.log('refer_ID', refer_ID)
    // console.log('ApplyTechID ------------->>>', ApplyTechID)

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();
        handleSubmitMail();
        // console.log('all submit data', formData)
        try {
            const response = await axios.post(`${apiUrl}/user/create`, formData);
            if (response.status === 200) {
                setLoading(false)
                if (response.data.success === true) {
                    Swal.fire({
                        title: 'Form Submit Successfully!',
                        text: 'You clicked the button.',
                        icon: 'success',
                    });
                    setformData({
                        ...initialFormData,
                        jobType: '',
                        applyFor: '',
                    });
                    // console.log('my refer_ID id is >>>>>>>', refer_ID);
                    navigate(`/interview_session?id=${response.data.data.id}&refer=${refer_ID}&apply=${ApplyTechID}`);
                } else if (response.data.success === false) {
                    console.log('response.data.error', response.data.error);
                } else {
                    alert("Data posting failed");
                }
            }
        } catch (error) {
            console.error("Error posting data1:", error);
            toast.error(error.response.data.error, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false)
        }
    };
    // ...

    useEffect(() => {
        let jobTypeValue = '';
        if (formData.jobType === 'fresher') {
            jobTypeValue = '1';
        } else if (formData.jobType === 'experience') {
            jobTypeValue = '2';
        }
        setrefer_ID(jobTypeValue);
        const selectedTech = ApplyTech.find((item) => item.applyFor === formData.applyFor);
        if (selectedTech) {
            const techId = selectedTech.id;
            setApplyTechID(techId);
        }
    }, [formData.applyFor, formData.jobType, ApplyTech]);

    useEffect(() => {
        axios
            .get(`${apiUrl}/jobprofile/getall`)
            .then((response) => {
                setApplyTech(response.data.data);
            })
            .catch((error) => {
                console.error("Error fetching Technology data:", error);
            });
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setformData((prevData) => ({ ...prevData, [name]: value }));
        if (name === 'jobType') {
            setShowDropdown(value === 'experience');
        }
    };

    const handleSubmitMail = async () => {
        // console.log('formData data for mail user >>>>>>>>>>>>', formData)
        try {
            const response = await axios.post(`${apiUrl}/mailUrl/send-email`, formData);
            // console.log('Mail sent successfully', response);
        } catch (error) {
            console.error('Mail fetch error', error);
        }
    };

    const filteredOptions = ApplyTech.filter((option) => option.status === 1);

    return (
        <div className='flex flex-col lg:flex-row justify-center items-center w-full h-full md:h-screen md:w-full'>
            {loading === true ? <LoaderSpiner text="Loading..." desc="please don't refresh or back while data is loading" /> : null}
            <div className='px-5 lg:px-10 py-7 lg:w-6/12 bg-[#eb951d] md:w-full h-full'>
                <div className='flex flex-col items-center justify-center gap-3 md:w-full'>
                    <div className='flex flex-row items-center justify-center'>
                        <div className='w-20'>
                            <img className='rounded-full' src='logo192.png' alt="" />
                        </div>
                        <h2 className='text-white text-2xl pl-4'>ENS Enterprises Pvt Ltd</h2>
                    </div>
                    <p className='text-2xl text-white'>Your place to work Plan.<br /> Create. Control.</p>
                    <div className='w-full flex justify-center'>
                        <img src="/images/illustration.png" alt="" className="md:w-2/4" />
                    </div>
                </div>
            </div>
            <div className="lg:w-6/12 mt-2 flex flex-col items-center justify-center md:mt-4 sm:mt-4">
                <div className="shadow-lg lg:w-9/12 border px-4 lg:px-6  rounded-lg bg-white sm:mt-4">
                    <form action="" className="mb-3 lg:w-full " onSubmit={handleSubmit}>
                        <p className="text-2xl text-center my-3">Please sign up to start your session</p>
                        <div className="form-group mb-3 ">
                            <label className="block text-gray-700 mb-2">First Name :</label>
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                className="form-control w-full border border-gray-500 p-2 rounded-md p-2 outline-none focus:shadow-outline"
                                placeholder="Enter Your First Name"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label className="block text-gray-700 mb-2">Last Name :</label>
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                className="form-control w-full border border-gray-500 p-2 rounded-md p-2 outline-none focus:shadow-outline"
                                placeholder="Enter Your Last Name"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label className="block text-gray-700 mb-2">Email Id :</label>
                            <input
                                type="email"
                                value={formData.email}
                                name="email"
                                className="form-control w-full border border-gray-500 p-2 rounded-md p-2 outline-none focus:shadow-outline"
                                placeholder="Enter Your Email Id"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label className="block text-gray-700 mb-2">Phone Number :</label>
                            <input
                                type="text"
                                className="form-control w-full border border-gray-500 p-2 rounded-md p-2 outline-none focus:shadow-outline"
                                name="mobileNumber"
                                value={formData.mobileNumber}
                                placeholder="Enter Your Phone Number"
                                onChange={handleInputChange}
                                maxLength={10}
                                required
                            />
                        </div>
                        <div className="form-group mb-5">
                            <label className="block text-gray-700 mb-2">Reference by :</label>
                            <input
                                type="text"
                                className="form-control w-full border border-gray-500 p-2 rounded-md outline-none focus:shadow-outline"
                                name="reference_by"
                                value={formData.reference_by}
                                placeholder="Reference by"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className={showDropdown ? "block mb-2" : "d-flex mb-3 aligin-center"}>
                            <div className="flex justify-between items-center">
                                <label className="block text-gray-700 w-24">Job Type :</label>
                                <div className="flex justify-between items-center w-48">
                                    <div className="">
                                        <input
                                            className="form-check-input mr-1 "
                                            type="radio"
                                            name="jobType"
                                            value="fresher"
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <label className="form-check-label text-black">Fresher</label>
                                    </div>
                                    <div className="form-group ">
                                        <input
                                            className="form-check-input mr-1  "
                                            type="radio"
                                            name="jobType"
                                            value="experience"
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <label className="form-check-label text-black ">Experience</label>
                                    </div>
                                </div>
                                <div className="form-group ">
                                    {showDropdown ?
                                        <div className="dropdown-popup emp reg ml-4">
                                            <select className="border border-gray-500 p-2 rounded-md lg:w-full  w-[100px]" name="experience_year" onChange={handleInputChange} value={formData.experience_year}>
                                                <option value="">Select Experience</option>
                                                <option value="6 Month">6 Month</option>
                                                <option value="1.5 Year">1.5 Year</option>
                                                <option value="2 Year">2 Year</option>
                                                <option value="2.5 Year">2.5 Year</option>
                                                <option value="3 Year">3 Year</option>
                                                <option value="3 Year">3.5 Year</option>
                                                <option value="4 Year">4 Year</option>
                                            </select>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="input-group flex-nowrap ">
                            <label className="block text-gray-700 mb-2">Apply For :</label>
                            <select
                                className=" border border-gray-500 p-2 rounded-md w-full"
                                aria-label="Default select example"
                                name="applyFor"
                                value={formData.applyFor}
                                onChange={handleInputChange}
                                required
                            >
                                <option defaultValue>--Select an Option--</option>
                                {filteredOptions.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.value}
                                        name="applyFor"
                                        required
                                    >
                                        {option.applyFor}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group flex items-center justify-end mt-4">
                            <SecButton text='Submit' className="bg-[#eb951d]" />
                        </div>
                        <ToastContainer />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CandidateRegistration;