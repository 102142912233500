import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import './getallquestionsets.css'
import Active from '../ApplyFor/active-mark.png'
import Inactive from '../ApplyFor/cross-mark.png'
import Layout from '../layout';
import LoaderSpiner from '../loader';
import SecButton from '../secButton';

function GetAllQuestionSets() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [getallquestionsets, setgetallquestionsets] = useState([])
    const [getJobType, setgetJobType] = useState([])
    const [getquestionset, setGetQuestionSet] = useState([])
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)

    const getallsets = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${apiUrl}/questionSets/getAllSets`)
            setgetallquestionsets(response.data?.data)
            // console.log('get all set response >>>>>>>>', response)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching applyfor data', error)
            setLoading(false)
        }
    }

    const handlegetJobType = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${apiUrl}/jobprofile/getall`);
            setgetJobType(response.data?.data);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching question sets data:', error);
            setLoading(false)
        }
    }

    useEffect(() => {
        getallsets();
        handlegetJobType();
    }, [])

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`${apiUrl}/questionSets/deleteQuesSet`, {
                        data: {
                            id: id,
                            sets_id: id
                        },
                    })
                    .then(() => {
                        getallsets();
                        handlegetJobType()
                        toast.success('Delete Question set Successfully', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
                    .catch((error) => {
                        console.error("Error deleting data:", error);
                    });
                Swal.fire(
                    "Deleted!",
                    "Your file has been deleted.",
                    "success"
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    "Cancelled",
                    "Your imaginary file is safe :)",
                    "error"
                );
            }
        });
    };

    const handleGetQuestionset = async (id) => {
        setOpen(true)
        try {
            const res = await axios.post(`${apiUrl}/questionSets/getSetQuestion`, {
                set_id: id
            })
            const setdata = res?.data.data
            setGetQuestionSet(setdata)
        } catch (error) {
            console.error('get question set data', error)
        }
    }
    // console.log('all question set data getSetQuestion >>>>>>>>>>>>', getquestionset)


    return (
        <>
            {loading === true ? <LoaderSpiner text="Loading..." desc="please don't refresh or back while data is loading" /> : null}
            <Layout setDropdown={true}>
                <div>
                    <div className="border-b border-gray-200 h-[480px] overflow-y-scroll">
                        <table className="min-w-full  divide-y divide-gray-200">
                            <thead className="bg-gray-50 sticky top-0">
                                <tr  >
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                                    >
                                        Id
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                                    >
                                        Question Sets Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                                    >
                                        Refer To
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                                    >
                                        Job Type
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                                    >
                                        Status
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-medium tracking-wider text-gray-500 uppercase"
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                className="bg-white divide-y divide-gray-200 cursor-pointer overflow-x-scroll max-h-[300px]"
                            >
                                {getallquestionsets.map((item, index) => {
                                    const getfilterapplyfor = getJobType.find((applyForItem) => {
                                        return applyForItem.id === item.jobType_id;
                                    });
                                    return (
                                        <tr key={index} className="transition-all hover:bg-gray-100 hover:shadow-lg text-center">
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900">
                                                    {index + 1}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900">
                                                    {item.sets_name}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                <div className="text-sm text-gray-900">
                                                    {item.referTo_id === 1 ? "Fresher" : "Experience"}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                <div>
                                                    {getfilterapplyfor ? getfilterapplyfor.applyFor : ''}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900 flex justify-center">
                                                    <img
                                                        src={parseInt(item.status) ? Active : Inactive}
                                                        className="w-4"
                                                    />
                                                </div>
                                            </td>
                                            <td className="py-4 text-sm whitespace-nowrap w-[100px]">
                                                <div className="flex justify-between px-4">
                                                    <button onClick={() => handleGetQuestionset(item.id)} className="bg-green-600 p-1 rounded text-white">
                                                        <svg
                                                            className="svg-icon"
                                                            style={{
                                                                width: '22',
                                                                height: '22',
                                                                verticalAlign: 'middle',
                                                                fill: 'white',
                                                                overflow: 'hidden',
                                                            }}
                                                            viewBox="0 0 1024 1024"
                                                            version="1.1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        ><path d="M945.980952 770.438095c-4.87619-4.87619-112.152381-170.666667-248.685714-170.666666s-243.809524 160.914286-248.685714 170.666666l-14.628572 19.504762 14.628572 19.504762c4.87619 4.87619 112.152381 170.666667 248.685714 170.666667s243.809524-160.914286 248.685714-170.666667l14.628572-19.504762-14.628572-19.504762z m-248.685714 131.657143c-68.266667 0-136.533333-68.266667-165.790476-112.152381 34.133333-43.885714 102.4-112.152381 165.790476-112.152381s136.533333 68.266667 165.790476 112.152381c-34.133333 43.885714-97.52381 112.152381-165.790476 112.152381z" /><path d="M697.295238 731.428571c-34.133333 0-58.514286 24.380952-58.514286 58.514286s24.380952 58.514286 58.514286 58.514286 58.514286-24.380952 58.514286-58.514286-29.257143-58.514286-58.514286-58.514286z" /><path d="M160.914286 219.428571h97.523809v78.019048h497.371429V219.428571h97.523809v375.466667h97.52381V121.904762h-195.047619V43.885714H263.314286v73.142857h-195.047619V975.238095H438.857143v-97.523809H160.914286V219.428571z m199.923809-78.019047h302.32381v58.514286H360.838095V141.409524z" /><path d="M263.314286 414.47619h497.371428v97.52381H263.314286zM263.314286 624.152381H438.857143v97.523809H263.314286z" /></svg>
                                                    </button>
                                                    <button onClick={() => handleDelete(item.id)} className="bg-red-600 p-1 rounded text-white">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="22" stroke="currentColor" fill="white" viewBox="0 0 32 32" id="delete"><path d="M24.2,12.193,23.8,24.3a3.988,3.988,0,0,1-4,3.857H12.2a3.988,3.988,0,0,1-4-3.853L7.8,12.193a1,1,0,0,1,2-.066l.4,12.11a2,2,0,0,0,2,1.923h7.6a2,2,0,0,0,2-1.927l.4-12.106a1,1,0,0,1,2,.066Zm1.323-4.029a1,1,0,0,1-1,1H7.478a1,1,0,0,1,0-2h3.1a1.276,1.276,0,0,0,1.273-1.148,2.991,2.991,0,0,1,2.984-2.694h2.33a2.991,2.991,0,0,1,2.984,2.694,1.276,1.276,0,0,0,1.273,1.148h3.1A1,1,0,0,1,25.522,8.164Zm-11.936-1h4.828a3.3,3.3,0,0,1-.255-.944,1,1,0,0,0-.994-.9h-2.33a1,1,0,0,0-.994.9A3.3,3.3,0,0,1,13.586,7.164Zm1.007,15.151V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Zm4.814,0V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Z"></path></svg>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {open ?
                        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white p-8 rounded-md w-96 w-[900px]">
                                <div className='flex justify-end mb-4'>
                                    <SecButton text="Close X" onClick={() => setOpen(false)} className="bg-red-600" />
                                </div>
                                <div className="border-b border-gray-200  h-[480px] overflow-y-scroll">
                                    <table className=" divide-y divide-gray-200 border border-1 gray-600 w-full">
                                        <thead className="bg-gray-50 sticky top-0">
                                            <tr  >
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                                                >
                                                    S.No
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                                                >
                                                    Question
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                                                >
                                                    Status
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                                                >
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody
                                            className="bg-white divide-y divide-gray-200 cursor-pointer overflow-x-scroll max-h-[300px]"
                                        >
                                            {getquestionset.map((item, index) => {
                                                return (
                                                    <tr key={index} className="transition-all hover:bg-gray-100 hover:shadow-lg text-center">
                                                        <td className="px-6 py-2 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900">
                                                                {index + 1}
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-2 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900">
                                                                {item.ens_question.question}
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-2 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900 flex justify-center">
                                                                <img
                                                                    src={item.status === 1 ? Inactive : Active}
                                                                    alt=''
                                                                    className="w-4"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-2 text-sm text-gray-500 whitespace-nowrap flex justify-center">
                                                            <div className="flex gap-2">
                                                                <button onClick={() => handleDelete(item.id)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" stroke="currentColor" viewBox="0 0 32 32" id="delete"><path d="M24.2,12.193,23.8,24.3a3.988,3.988,0,0,1-4,3.857H12.2a3.988,3.988,0,0,1-4-3.853L7.8,12.193a1,1,0,0,1,2-.066l.4,12.11a2,2,0,0,0,2,1.923h7.6a2,2,0,0,0,2-1.927l.4-12.106a1,1,0,0,1,2,.066Zm1.323-4.029a1,1,0,0,1-1,1H7.478a1,1,0,0,1,0-2h3.1a1.276,1.276,0,0,0,1.273-1.148,2.991,2.991,0,0,1,2.984-2.694h2.33a2.991,2.991,0,0,1,2.984,2.694,1.276,1.276,0,0,0,1.273,1.148h3.1A1,1,0,0,1,25.522,8.164Zm-11.936-1h4.828a3.3,3.3,0,0,1-.255-.944,1,1,0,0,0-.994-.9h-2.33a1,1,0,0,0-.994.9A3.3,3.3,0,0,1,13.586,7.164Zm1.007,15.151V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Zm4.814,0V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Z"></path></svg>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> : null}
                    {/* ---------------------- */}
                </div>
            </Layout >
        </>
    );
}

export default GetAllQuestionSets;
