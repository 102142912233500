import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Layout from "../layout";
import LoaderSpiner from "../loader";
import PreButton from "../preButton";
import SecButton from "../secButton";

const QuestionSets = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [technology, setTechnology] = useState([]);
  const [technologyOption, setTechnologyOption] = useState("");
  const [getTechQuestion, setGetQuestionData] = useState([]);
  const [Jobprofile, setJobprofile] = useState([]);
  const [addmarkquestion, setAddMarkQusetion] = useState([]);
  const [selectall, setselectall] = useState([]);
  const [gettechnologyvalue, setgettechnologyvalue] = useState("")
  const [addquestion, setAddquestion] = useState([]);
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const navigate = useNavigate()
  const [Questiondata, setQuestiondata] = useState({
    technology_id: "",
    referTo_id: "",
    SelectJobprofile: "",
    technologyOptionRef: "",
    QuestionSetName: "",
  })

  function changeHandler(e) {
    setQuestiondata((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }
  // -----
  const getAllTech = async () => {
    await axios
      .get(`${apiUrl}/technology/getall`)
      .then((response) => {
        setTechnology(response.data.data);
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching Technology data:", error);
      });
  };

  useEffect(() => {
    getAllTech();
    jobprofiledata();
  }, []);

  // ------------

  const getAllQues = async () => {
    document.getElementById("referTo_id").value = "";
    try {
      const response = await axios.post(`${apiUrl}/questionSets/getQuesSets`, {
        technology_id: technologyOption,
        referTo_id: Questiondata.technologyOptionRef,
      });
      const filteredData = response.data?.data.filter(
        (item) => item.technology_id === parseInt(technologyOption)
      );
      setGetQuestionData(filteredData);
    } catch (error) {
      console.error("Error fetching all questionSets data:", error);
    }
  };

  const GetAllFilterQuestion = async (id) => {
    try {
      const response = await axios.post(`${apiUrl}/questionSets/getQuesSets`, {
        technology_id: technologyOption,
        referTo_id: Questiondata.technologyOptionRef,
      });
      const filteredData = response.data?.data.filter(
        (item) => item.referTo_id === parseInt(id)
      );
      setGetQuestionData(filteredData);
    } catch (error) {
      console.error("Error fetching filter questionSets data:", error);
    }
  };

  // ------------

  const jobprofiledata = () => {
    axios
      .get(`${apiUrl}/jobprofile/getall`)
      .then((response) => {
        setJobprofile(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching jobprofile data:", error);
      });
  };

  // --------

  const handleSelectAll = () => {
    const updatedSelectAll =
      selectall.length === getTechQuestion.length
        ? []
        : getTechQuestion.map((item) => item.id);
    setselectall(updatedSelectAll);
    setAddMarkQusetion(updatedSelectAll);
    // console.log("Select All is >> :", updatedSelectAll);
    const updatedGetTechQuestion = getTechQuestion.map((item) => {
      return {
        ...item,
        selected: !updatedSelectAll.includes(item.id),
      };
    });
    setGetQuestionData(updatedGetTechQuestion);
    // console.log("all get question id", getTechQuestion);
  };

  const handleAddQuestion = () => {
    const updatedArr = [...addquestion];
    addmarkquestion.map((id) => {
      const isDuplicate = updatedArr.some((item) => item.id === id);
      if (!isDuplicate) {
        const selectedQuestion = getTechQuestion.find((item) => item.id === id);
        if (selectedQuestion) {
          updatedArr.push({
            id: selectedQuestion.id,
            name: selectedQuestion.question,
            gettechnologyvalue: gettechnologyvalue
          });
        }
      }
    });
    setAddquestion(updatedArr);
    setAddMarkQusetion([]);
  };

  const handleDeleteQuestion = (id) => {
    const updatedArr = addquestion.filter((item) => item.id !== id);
    setAddquestion(updatedArr);
  };

  const handleTechnologyOptionChange = (value) => {
    setTechnologyOption(value);
    const filteridtechnologydata = technology.filter((item) => {
      return item.id === Number(value)
    })
    setgettechnologyvalue(filteridtechnologydata[0].technology);
    // console.log('technology value is >>>', filteridtechnologydata[0].technology);
  };

  const handleQuestionSet = async () => {
    if (Questiondata.QuestionSetName.length > 0 && Questiondata.referTo_id > 0 && Questiondata.SelectJobprofile > 0) {
      const questionIds = addquestion.map((item) => item.id);
      const datafield = {
        setsName: Questiondata.QuestionSetName,
        referTo_id: Questiondata.referTo_id,
        jobType_id: parseInt(Questiondata.SelectJobprofile),
        question_id: questionIds,
        statusvalue: 1,
      }

      try {
        const response = await axios.post(`${apiUrl}/questionSets/createQuesSets`, datafield);
        // console.log('data submit successfull', response.data);
        if (response.status === 200) {
          toast.success('Question set created successfully', {
            position: toast.POSITION.TOP_CENTER,
          });
          navigate('/admin/get_all_questionsets');
        }
      } catch (error) {
        console.error('Error', error);
        // Handle error here if needed
      }
    } else {
      toast.warning('Please fill all the details', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // questionSetNameInputRef.current.focus();
    }
  };

  const filterJobprofile = Jobprofile.filter((Jobprofile) => Jobprofile.status === 1)

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading..." desc="please don't refresh or back while data is loading" /> : null}
      <Layout setDropdown={true}>
        <div className="flex justify-center items-center h-full">
          <div className="w-[95%] p-8 bg-white border border-1 rounded-md shadow-lg">
            <div className="flex justify-between h-ful">
              <div className="w-[350px]">
                <div className="mb-2">
                  <label htmlFor="" className="block text-gray-700 text-sm font-bold mb-2">
                    Question Set Name
                  </label>
                  <input
                    type="text"
                    className="w-full border border-gray-500 p-2 rounded-md"
                    placeholder="--question set name--"
                    value={Questiondata.QuestionSetName}
                    name="QuestionSetName"
                    required
                    autoComplete="off"
                    onChange={(e) => {
                      changeHandler(e)
                      // console.log("question set name is >>", QuestionSetName);
                    }}
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="" className="block text-gray-700 text-sm font-bold mb-2">
                    Select Technology
                  </label>
                  <select
                    className="w-full border border-gray-500 p-2 rounded-md"
                    id="technology_options"
                    aria-label=".form-select-sm example"
                    name="technology_id"
                    value={technologyOption}
                    onChange={(e) => {
                      handleTechnologyOptionChange(e.target.value);
                      changeHandler(e)
                    }}
                    onClick={() => getAllQues()}
                    required
                  >
                    <option value="">--Select Technology--</option>
                    {technology.filter(filtertech => filtertech.status === 1).map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.technology}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-2">
                  <label htmlFor="" className="block text-gray-700 text-sm font-bold mb-2">
                    Refer To
                  </label>
                  <select
                    className="w-full border border-gray-500 p-2 rounded-md"
                    id="referTo_id"
                    aria-label=".form-select-sm example"
                    name="referTo_id"
                    required
                    onChange={(e) => {
                      GetAllFilterQuestion(e.target.value);
                      changeHandler(e)
                      // setTechnologyOptionRef(e.target.value);
                      // console.log('refer id is >>>', e.target.value)
                    }}
                  >
                    <option value="">--Select Refer--</option>
                    <option value={1}>Fresher</option>
                    <option value={2}>Experience</option>
                  </select>
                </div>
                <div className="mb-2">
                  <label htmlFor="" className="block text-gray-700 text-sm font-bold mb-2">
                    Set for Job Profile
                  </label>
                  <select
                    required
                    className="w-full border border-gray-500 p-2 rounded-md "
                    id="SelectJobprofile"
                    aria-label=".form-select-sm example"
                    name="SelectJobprofile"
                    value={Questiondata.SelectJobprofile}
                    onChange={(e) => { changeHandler(e) }}
                  >
                    <option value="">--Select Job Profile--</option>
                    {filterJobprofile.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.applyFor}
                      </option>
                    ))}
                  </select>
                </div>
                <PreButton text="Submit Set" onClick={handleQuestionSet} className="ml-0" />
              </div>
              <div className={technologyOption.length > 0 ? 'rounded-md' : 'hidden'}>
                <div className="w-[680px] overflow-y-scroll">
                  <table className="divide-y divide-gray-200 border border-1 w-full transition duration-300 hover:border-green-500">
                    <thead className="bg-gray-50 sticky top-0 border border-1">
                      <tr>
                        <th
                          scope="col"
                          className="px-1 text-xs font-medium tracking-wider text-gray-500 uppercase"
                        >
                          <input
                            type="checkbox"
                            onClick={handleSelectAll}
                            className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out flex-end cursor-pointer"
                          />
                        </th>
                        <th
                          scope="col"
                          className="px-1 py-3 text-xs font-medium tracking-wider text-gray-500 uppercase"
                        >
                          S.No
                        </th>
                        <th
                          scope="col"
                          className="px-1 py-3 text-xs font-medium tracking-wider text-gray-500 uppercase"
                        >
                          Questions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 cursor-pointer overflow-x-scroll max-h-[300px]">
                      {getTechQuestion
                        .filter((item) => item.status === 1)
                        .map((item, index) => (
                          <tr key={index} className="transition-all hover:bg-gray-100 hover:shadow-lg text-center border border-1">
                            <td className="px-1 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900">
                                <input
                                  type="checkbox"
                                  className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
                                  checked={addmarkquestion.includes(item.id)}
                                  onChange={() => {
                                    const updatedAddMark = addmarkquestion.includes(item.id)
                                      ? addmarkquestion.filter((id) => id !== item.id)
                                      : [...addmarkquestion, item.id];
                                    setAddMarkQusetion(updatedAddMark);
                                  }}
                                />
                              </div>
                            </td>
                            <td className="px-1 py-2 whitespace-nowrap ">
                              <div className="text-sm text-gray-900">
                                {index + 1}
                              </div>
                            </td>
                            <td className="px-1 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900">
                                {item.question}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-between px-2 py-2">
                  <SecButton text='Preview Questions' onClick={() => setOpen(true)} className="bg-green-700" />
                  <SecButton text='Add Question Sets' onClick={handleAddQuestion} />
                </div>
              </div>
            </div>
          </div>
          {/* ================================================  */}
          {open ? (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white rounded-md p-8 w-[900px]">
                <div className="border-b border-gray-200 h-[400px] overflow-y-scroll">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-sm font-medium tracking-wider text-gray-700 uppercase"
                        >
                          S.No
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-sm font-medium tracking-wider text-gray-700 uppercase"
                        >
                          Question
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-sm font-medium tracking-wider text-gray-700 uppercase"
                        >
                          Technology
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-sm font-medium tracking-wider text-gray-700 uppercase"
                        >
                          Remove
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 cursor-pointer">
                      {addquestion.map((item, index) => (
                        <tr key={index} className="transition-all hover:bg-gray-100 hover:shadow-lg">
                          <td className="px-2 py-4 flex justify-center">
                            <div className="flex items-center">{index + 1}</div>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="text-center text-sm text-gray-900">{item.name}</div>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="text-center text-sm text-gray-900">{item.gettechnologyvalue}</div>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            <div className="text-sm text-gray-900 text-center">
                              <button onClick={() => handleDeleteQuestion(item.id)} className="bg-red-600 p-1 rounded text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" height="22" stroke="currentColor" viewBox="0 0 32 32" fill="white" id="delete"><path d="M24.2,12.193,23.8,24.3a3.988,3.988,0,0,1-4,3.857H12.2a3.988,3.988,0,0,1-4-3.853L7.8,12.193a1,1,0,0,1,2-.066l.4,12.11a2,2,0,0,0,2,1.923h7.6a2,2,0,0,0,2-1.927l.4-12.106a1,1,0,0,1,2,.066Zm1.323-4.029a1,1,0,0,1-1,1H7.478a1,1,0,0,1,0-2h3.1a1.276,1.276,0,0,0,1.273-1.148,2.991,2.991,0,0,1,2.984-2.694h2.33a2.991,2.991,0,0,1,2.984,2.694,1.276,1.276,0,0,0,1.273,1.148h3.1A1,1,0,0,1,25.522,8.164Zm-11.936-1h4.828a3.3,3.3,0,0,1-.255-.944,1,1,0,0,0-.994-.9h-2.33a1,1,0,0,0-.994.9A3.3,3.3,0,0,1,13.586,7.164Zm1.007,15.151V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Zm4.814,0V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Z"></path></svg>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-between align-center mt-2">
                  <SecButton text="Close X" onClick={() => setOpen(false)} className="bg-red-600" />
                </div>
              </div>
            </div>
          ) : null}
        </div >
        <ToastContainer />
      </Layout >
    </>
  );
};

export default QuestionSets;
