import React, { useEffect } from "react";
import "./thankyoupage.css";
import { NavLink } from "react-router-dom";

const Thankyoupage = () => {

    useEffect(() => {
        // Disable the browser's back button
        window.history.pushState(null, null, window.location.pathname);
        // Add a listener to prevent navigation using back/forward buttons
        window.addEventListener("popstate", function () {
            window.history.pushState(null, null, window.location.pathname);
        });
    }, []);

    return (
        <>
            <div className="start_main_parant">
                <div className="wrapper">
                    <svg>
                        <text x="50%" y="50%" dy=".35em" textAnchor="middle">
                            Thank you !
                        </text>
                    </svg>
                    <div className="total_ques_parant">
                        <p className="total_ques">for contacting us, we will get in touch with you soon..</p>
                        <div className="start_btn_div">
                            <NavLink to="/">
                                <button className="start_btn">Back to home</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Thankyoupage;
