import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import Active from '../ApplyFor/active-mark.png'
import Inactive from '../ApplyFor/cross-mark.png'
import Layout from "../layout";
import LoaderSpiner from "../loader";
import PreButton from "../preButton";
import SecButton from "../secButton";

const QuestionSection = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false)
  // ===============================================================ADD SUBJECT
  const rightAnswerOption = [
    { rightoption: "A" },
    { rightoption: "B" },
    { rightoption: "C" },
    { rightoption: "D" },
  ];

  const initialFormData = {
    question: "",
    option_A: "",
    option_B: "",
    option_C: "",
    option_D: "",
    right_ans: "",
    status: "",
    technology_id: "",
    referTo_id: "",
    id: "",
  };

  const [subjectData, setSubjectformData] = useState({ initialFormData });
  const [technology, setTechnology] = useState([]);
  const [getQuestionData, setGetQuestionData] = useState([]);

  // ============================================================UseEffect
  useEffect(() => {
    getAllTech();
    getAllQues();
  }, []);

  const getAllTech = async () => {
    setLoading(true)
    await axios
      .get(`${apiUrl}/technology/getall`)
      .then((response) => {
        setTechnology(response.data.data);
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching Technology data:", error);
        setLoading(false)
      });
  };

  const getAllQues = async () => {
    setLoading(true)
    axios
      .get(`${apiUrl}/question/all`)
      .then((response) => {
        setGetQuestionData(response.data.data);
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching Technology data:", error);
        setLoading(false)
      });
  };
  // console.log('get all question data >>>>>>>>>>>>>>>>>>>>>-------', getQuestionData)

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSubjectformData({ ...subjectData, [name]: value });
  };

  // ============================================================HandleSubmit
  const handleSubmit = async (event) => {
    event.preventDefault();
    const allInputValue = {
      question: subjectData.question,
      option_A: subjectData.option_A,
      option_B: subjectData.option_B,
      option_C: subjectData.option_C,
      option_D: subjectData.option_D,
      right_ans: subjectData.right_ans,
      status: parseInt(subjectData.status),
      technology_id: parseInt(subjectData.technology_id),
      referTo_id: parseInt(subjectData.referTo_id),
      id: parseInt(subjectData.id),
    };

    try {
      if (allInputValue.id) {
        //  update
        let body = {
          question: subjectData.question,
          option_A: subjectData.option_A,
          option_B: subjectData.option_B,
          option_C: subjectData.option_C,
          option_D: subjectData.option_D,
          right_ans: subjectData.right_ans,
          technology_id: parseInt(subjectData.technology_id),
          referTo_id: parseInt(subjectData.referTo_id),
          status: parseInt(subjectData.status),
        };
        const response = await fetch(
          `${apiUrl}/question/update/${allInputValue.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          }
        );
        if (response.ok) {
          const responseData = await response.json();
          setOpen(false);
          toast.success('Data update successfully', {
            position: toast.POSITION.TOP_RIGHT
          });
          await getAllQues();
        } else {
          alert("update failed");
        }
      } else {
        // create
        const response = await fetch(`${apiUrl}/question/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(allInputValue),
        });
        if (response.ok) {
          const responseData = await response.json();
          setOpen(false);
          toast.success('Data posting successfully', {
            position: toast.POSITION.TOP_RIGHT
          });
          await getAllQues();
        } else {
          alert("Data posting failed");
        }
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
    setSubjectformData({
      question: "",
      option_A: "",
      option_B: "",
      option_C: "",
      option_D: "",
      right_ans: "",
      status: "",
      technology_id: "",
      referTo_id: "",
    });
  };

  // ========================================================== END ADD SUBJECT ==============================
  // =========================================================== edit question ================================
  const editQuestion = (data) => {
    setOpen(true);
    setSubjectformData({
      question: data.question,
      option_A: data.option_A,
      option_B: data.option_B,
      option_C: data.option_C,
      option_D: data.option_D,
      right_ans: data.right_ans,
      status: data.status,
      technology_id: data.technology_id,
      referTo_id: data.referTo_id,
      id: data.id,
    });
  };

  // edit question............
  const deleteQuestion = (id) => {
    axios
      .delete(`${apiUrl}/question/delete/${id}`)
      .then(async (response) => {
        if (response.data.success) {
          toast.success('Data Delete successfully', {
            position: toast.POSITION.TOP_RIGHT
          });

          await getAllQues();
        } else {
          alert(response ? response.data.message : "Error");
        }
      })
      .catch((error) => {
        console.error("Error fetching Technology data:", error);
      });
  };
  // ==============================================================END QUESTION

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading..." desc="please don't refresh or back while data is loading" /> : null}
      <Layout setDropdown={true}>
        <div>
          <div className="question_back">
            <PreButton text='+ Add Question'
              onClick={() => setOpen(true)}
            />
            {open ? (
              <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-8 rounded-md w-[800px]">
                  <form onSubmit={handleSubmit} className="select_main_div">
                    <div className="flex justify-between mb-4">
                      <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="technology_id">
                          Select Technology
                        </label>
                        <select
                          className="w-[320px] border border-gray-500 p-2 rounded-md"
                          aria-label=".form-select-sm example"
                          name="technology_id"
                          value={subjectData.technology_id}
                          onChange={handleInputChange}
                          required
                        >
                          <option value=''>
                            --Select Technology--
                          </option>
                          {technology.map((option, index) => (
                            <option
                              key={index}
                              value={option.id}
                              name="technology_id"
                              required
                              selected={
                                parseInt(option.id) === parseInt(subjectData.technology_id)
                              }
                            >
                              {option.technology}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/* ==============================================================Refer To */}
                      <div >
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="technology_id">
                          Refer To :
                        </label>
                        <select
                          className="w-[320px] border border-gray-500 p-2 rounded-md"
                          aria-label=".form-select-sm example"
                          name="referTo_id"
                          value={subjectData.referTo_id}
                          onChange={handleInputChange}
                          required
                        >
                          <option value=''> --Select Refer-- </option>
                          <option value={1}>Fresher</option>
                          <option value={2}>Experience</option>
                        </select>
                      </div>
                    </div>
                    {/* ==============================================================Question */}
                    <div className="qetgrqer mb-3">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Question
                      </label>
                      <textarea
                        className="w-full border border-gray-500 p-2 rounded-md"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        name="question"
                        value={subjectData.question}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>
                    {/* ==============================================================Option A */}
                    <div className="flex justify-between mb-4">
                      <div className="input-group flex-nowrap">
                        <label htmlFor="someInput" className="block text-gray-700 text-sm font-bold mb-2">
                          Option A
                        </label>
                        <input
                          type="text"
                          name="option_A"
                          value={subjectData.option_A}
                          onChange={handleInputChange}
                          className="w-[320px] border border-gray-500 p-2 rounded-md"
                          placeholder="Option A"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          required
                        />
                      </div>
                      <div className="input-group flex-nowrap">
                        <label htmlFor="someInput" className="block text-gray-700 text-sm font-bold mb-2">
                          Option B
                        </label>
                        <input
                          type="text"
                          name="option_B"
                          value={subjectData.option_B}
                          onChange={handleInputChange}
                          className="w-[320px] border border-gray-500 p-2 rounded-md"
                          placeholder="Option B"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex justify-between mb-4">
                      <div className="input-group flex-nowrap">
                        <label htmlFor="someInput" className="block text-gray-700 text-sm font-bold mb-2">
                          Option C
                        </label>
                        <input
                          type="text"
                          name="option_C"
                          value={subjectData.option_C}
                          onChange={handleInputChange}
                          className="w-[320px] border border-gray-500 p-2 rounded-md"
                          placeholder="Option C"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                        />
                      </div>
                      <div className="input-group flex-nowrap">
                        <label htmlFor="someInput" className="block text-gray-700 text-sm font-bold mb-2">
                          Option D
                        </label>
                        <input
                          type="text"
                          name="option_D"
                          value={subjectData.option_D}
                          onChange={handleInputChange}
                          className="w-[320px] border border-gray-500 p-2 rounded-md"
                          placeholder="Option D"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                        />
                      </div>
                    </div>
                    {/* =======================================================Select Right Ans */}
                    <div className="flex justify-between mb-4">
                      <div className="select_subject_input">
                        <label htmlFor="someInput" className="block text-gray-700 text-sm font-bold mb-2">
                          Select Right Answer
                        </label>
                        <select
                          className="w-[320px] border border-gray-500 p-2 rounded-md"
                          aria-label=".form-select-sm example"
                          name="right_ans"
                          value={subjectData.right_ans}
                          onChange={handleInputChange}
                          required
                        >
                          <option selected>Select Answer</option>
                          {rightAnswerOption.map((item, index) => (
                            <option
                              key={index}
                              value={item.right_ans}
                              name="right_ans"
                              required
                            >
                              {item.rightoption}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/* ============================================================= Status */}
                      <div className="input-group flex-nowrap">
                        <label htmlFor="someInput" className="block text-gray-700 text-sm font-bold mb-2">
                          Status
                        </label>
                        <select
                          className="w-[320px] border border-gray-500 p-2 rounded-md"
                          aria-label=".form-select-sm example"
                          name="status"
                          value={subjectData.status}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select Status</option>
                          <option value={1}>Active</option>
                          <option value={0}>Inactive</option>
                        </select>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <SecButton
                        text="Close"
                        className="bg-red-600 hover:bg-red-400 hover:text-black"
                        onClick={() => setOpen(false)}
                      />
                      <button type="submit" onChange={handleInputChange}>
                        {subjectData.id ? <SecButton text="Update" /> : <SecButton text="Submit" />}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
            {/* ========================================================END ADD SUBJECT*/}
          </div>
          <div className="flex flex-col mt-4">
            <div className="inline-block min-w-full py-2 align-middle p-2">
              <div className="border-b border-gray-200 h-[480px] overflow-y-scroll">
                <table className="min-w-full  divide-y divide-gray-200">
                  <thead className="bg-gray-50 sticky top-0">
                    <tr  >
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                      >
                        S.No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                      >
                        Questions
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                      >
                        Technology
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                      >
                        Refer
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                      >
                        Answer
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="bg-white divide-y divide-gray-200 cursor-pointer overflow-x-scroll max-h-[300px]"
                  >
                    {getQuestionData.map((item, index) => {
                      const maintechnologydata = technology.find((technologyItem) => {
                        return technologyItem.id === item.technology_id;
                      });

                      return (
                        <tr key={index} className="transition-all hover:bg-gray-100 hover:shadow-lg text-center">
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {index + 1}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {item.question}
                            </div>
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {maintechnologydata ? maintechnologydata.technology : ''}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {item.referTo_id === 1 ? "Fresher" : "Experience"}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {item.right_ans}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900 flex justify-center">
                              <img
                                src={parseInt(item.status) ? Active : Inactive}
                                className="w-4"
                              />
                            </div>
                          </td>
                          <td className="px-6 py-4 text-sm whitespace-nowrap flex justify-center">
                            <div className="flex gap-2">
                              <button onClick={() => {
                                editQuestion(item);
                              }} className="bg-green-600 p-1 rounded text-white">
                                <svg fill="none" height="22" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="22" xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>

                              </button>
                              <button onClick={() => {
                                deleteQuestion(item.id);
                              }} className="bg-red-600 p-1 rounded text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" height="22" stroke="currentColor" viewBox="0 0 32 32" fill="white" id="delete"><path d="M24.2,12.193,23.8,24.3a3.988,3.988,0,0,1-4,3.857H12.2a3.988,3.988,0,0,1-4-3.853L7.8,12.193a1,1,0,0,1,2-.066l.4,12.11a2,2,0,0,0,2,1.923h7.6a2,2,0,0,0,2-1.927l.4-12.106a1,1,0,0,1,2,.066Zm1.323-4.029a1,1,0,0,1-1,1H7.478a1,1,0,0,1,0-2h3.1a1.276,1.276,0,0,0,1.273-1.148,2.991,2.991,0,0,1,2.984-2.694h2.33a2.991,2.991,0,0,1,2.984,2.694,1.276,1.276,0,0,0,1.273,1.148h3.1A1,1,0,0,1,25.522,8.164Zm-11.936-1h4.828a3.3,3.3,0,0,1-.255-.944,1,1,0,0,0-.994-.9h-2.33a1,1,0,0,0-.994.9A3.3,3.3,0,0,1,13.586,7.164Zm1.007,15.151V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Zm4.814,0V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Z"></path></svg>
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Layout >
    </>
  );
};

export default QuestionSection;