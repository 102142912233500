import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
// import "./empdata.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import ViewTest from '../ApplyFor/active-mark.png'
import NoViewTest from '../ApplyFor/cross-mark.png'
import Layout from "../layout";
import LoaderSpiner from "../loader";
import SecButton from "../secButton";


const EmployeeData = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [Empdata, setEmpData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordpage] = useState(20);
  const [totalUsers, setTotalUsers] = useState([]);
  const [Searchdata, setSearchdata] = useState('');
  const [addmark, setAddMark] = useState([]);
  const [getdetails, setGetDetails] = useState([]);
  const [isToggled, setIsToggled] = useState(false);
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const npage = Math.ceil(totalUsers / recordsPerPage);
  const numbers = [...Array(npage).keys()].map(num => num + 0);
  const startSerial = currentPage * recordsPerPage + 1;

  const getempdata = () => {
    setLoading(true)
    axios.get(`${apiUrl}/adminSideUser/paginate?pageNo=${currentPage}&limit=${recordsPerPage}`)
      .then((response) => {
        const reversedData = response.data?.pagination.data.reverse();
        setEmpData(reversedData);
        setTotalUsers(response.data?.pagination.totalUsers);
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching employee data:", error);
        setLoading(false)
      });
  };

  useEffect(() => {
    getempdata();
  }, [currentPage, totalUsers, recordsPerPage]);

  function prepage() {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1)
      getempdata();
      // console.log('currentPage1', currentPage)
    }
  }
  function changeCpage(id) {
    setCurrentPage(id)
    // console.log('currentPage2', currentPage)
    getempdata();
  }
  function nextpage() {
    if (currentPage + 1 <= npage - 1) {
      setCurrentPage(currentPage + 1);
      getempdata();
      // console.log('currentPage3', currentPage);
    } else {
      alert('next page is empty');
    }
  }

  function handleSearch() {
    axios.get(`${apiUrl}/adminSideUser/searchFor?by=${Searchdata}`)
      .then((response) => {
        const searchData = response.data;
        if (searchData?.data?.length > 0) {
          setEmpData(searchData.data);
        }
        // console.log('Search data:', searchData);
      })
      .catch((error) => {
        toast.warning('No matching data found', {
          position: toast.POSITION.TOP_RIGHT
        });
        console.error('Error fetching search data:', error);
      });
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${apiUrl}/adminSideUser/deleteusers/${id}`)
          .then(() => {
            getempdata();
            toast.success('Delete Employee Data Successfully', {
              position: toast.POSITION.TOP_RIGHT
            });
          })
          .catch((error) => {
            console.error("Error deleting data:", error);
          });
        Swal.fire(
          "Deleted!",
          "Your file has been deleted.",
          "success"
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelled",
          "Your imaginary file is safe :)",
          "error"
        );
      }
    });
  };

  function handleBulkDelete() {
    setAddMark(addmark)
    if (addmark.length > 0) {
      axios
        .post(`${apiUrl}/adminSideUser/bulk`, { ids: addmark })
        .then((response) => {
          getempdata();
          setAddMark([]);
          alert("Bulk delete successful");
        })
        .catch((error) => {
          console.error("Error performing bulk delete:", error);
        });
    } else {
      setAddMark(false)
      // console.warn("No items selected for bulk delete");
    }
  }

  const handleGetQuestionDetails = async (id) => {
    setLoading(true)
    setOpen(true);
    try {
      const res = await axios.get(`${apiUrl}/adminSideUser/getusersid/${id}`)
      const userdata = [res.data.data];
      setGetDetails(userdata);
      setLoading(false)
      console.log('myall data', userdata)
    } catch (error) {
      console.error('get candidate data api error', error)
      setLoading(false)
    }
  }

  const handleSelectAll = () => {
    const updatedAddMark = addmark.length === Empdata.length ? [] : Empdata.map((item) => item.id);
    setAddMark(updatedAddMark);
    const updatedEmpData = Empdata.map((item) => {
      return {
        ...item,
        selected: !updatedAddMark.includes(item.id),
      };
    });
    setEmpData(updatedEmpData);
    // console.log('bulk action delete', updatedAddMark)
  };


  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading..." desc="please don't refresh or back while data is loading" /> : null}
      <Layout setDropdown={true}>
        <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row justify-between border-1-3 border-cyan-300 m-5">
          <div className="mr-6">
            <h1 className="text-4xl font-semibold mb-2">Candidate</h1>
          </div>
          <div className="flex flex-wrap items-start justify-end">
            <div>
              <label
                htmlFor="SearchField"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-full h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block w-[250px] p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-none focus:border-[#EB5454] dark:border-gray-600 dark:placeholder-gray-400 dark:text-[#000] dark:focus:ring-[#EB5454] dark:focus:border-[#EB5454]"
                  placeholder="Search . . ."
                  value={Searchdata}
                  onChange={(e) => setSearchdata(e.target.value)}
                  onKeyDown={handleSearch}
                />
              </div>
            </div>

            <button className="inline-flex px-5 py-3 text-red-600 hover:text-red-700 focus:text-red-700 hover:bg-red-100 focus:bg-blue-100 border border-red-600 rounded-md mx-3" onClick={() => setIsToggled(!isToggled)}>
              <svg
                aria-hidden="true"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className=" h-5 w-5 mt-0.5 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
              Bulk Actions
              {addmark.length > 0 && isToggled ? (
                <div className={`${isToggled ? 'block' : 'none'}`}>
                  <SecButton text="Delete" onClick={handleBulkDelete} className="absolute top-24 right-20 z-[9999] bg-red-600" />
                </div>
              ) : null}
            </button>
          </div>
        </div>
        <div className="table-container">
          <table className="text-center w-full">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-2 py-3 text-xs font-medium tracking-wider text-gray-500 uppercase flex justify-between"
                >
                  <input
                    type="checkbox"
                    onClick={handleSelectAll}
                    className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out flex-end cursor-pointer"
                  />
                  <span>S.No</span>
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                >
                  First Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                >
                  Last Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider text-gray-500 uppercase"
                >
                  Phone
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                >
                  Job Type
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                >
                  Apply for
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                >
                  Exam Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 cursor-pointer">
              {Empdata
                .filter((item) => item.status === 1)
                .map((item, index) => (
                  <tr key={item.id} className="transition-all hover:bg-gray-100 hover:shadow-lg">
                    <td className="px-2 py-4 flex">
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
                        checked={addmark.includes(item.id)}
                        onChange={() => {
                          const updatedAddMark = addmark.includes(item.id)
                            ? addmark.filter((id) => id !== item.id)
                            : [...addmark, item.id];
                          setAddMark(updatedAddMark);
                          console.log("addmark:", updatedAddMark);
                        }}
                      />
                      <div className="flex items-center ml-5">
                        <p className="text-sm font-medium text-gray-900">
                          {startSerial + index}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {item.firstName}
                      </div>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {item.lastName}
                      </div>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {item.email}
                      </div>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {item.mobileNumber}
                      </div>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {item.jobType}
                      </div>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {item.applyFor}
                      </div>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap flex justify-center">
                      <img src={item.status === 1 ? ViewTest : NoViewTest} alt="" className="w-4" />
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div className='text-sm text-gray-900 flex items-center justify-between'>
                        <Link to={`/admin/editdata/${item.id}`}>
                          <button className="bg-green-600 p-1 rounded text-white">
                            <svg fill="none" height="22" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="22" xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                          </button>
                        </Link>
                        <button
                          onClick={() => handleGetQuestionDetails(item.id)}
                          className="bg-blue-500 p-1 rounded text-white"
                        >
                          <svg
                            className="svg-icon"
                            style={{
                              width: '22',
                              height: '22',
                              verticalAlign: 'middle',
                              fill: 'currentColor',
                              overflow: 'hidden',
                            }}
                            viewBox="0 0 1024 1024"
                            fill="white"
                            height="22"
                            width="22"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          ><path d="M945.980952 770.438095c-4.87619-4.87619-112.152381-170.666667-248.685714-170.666666s-243.809524 160.914286-248.685714 170.666666l-14.628572 19.504762 14.628572 19.504762c4.87619 4.87619 112.152381 170.666667 248.685714 170.666667s243.809524-160.914286 248.685714-170.666667l14.628572-19.504762-14.628572-19.504762z m-248.685714 131.657143c-68.266667 0-136.533333-68.266667-165.790476-112.152381 34.133333-43.885714 102.4-112.152381 165.790476-112.152381s136.533333 68.266667 165.790476 112.152381c-34.133333 43.885714-97.52381 112.152381-165.790476 112.152381z" /><path d="M697.295238 731.428571c-34.133333 0-58.514286 24.380952-58.514286 58.514286s24.380952 58.514286 58.514286 58.514286 58.514286-24.380952 58.514286-58.514286-29.257143-58.514286-58.514286-58.514286z" /><path d="M160.914286 219.428571h97.523809v78.019048h497.371429V219.428571h97.523809v375.466667h97.52381V121.904762h-195.047619V43.885714H263.314286v73.142857h-195.047619V975.238095H438.857143v-97.523809H160.914286V219.428571z m199.923809-78.019047h302.32381v58.514286H360.838095V141.409524z" /><path d="M263.314286 414.47619h497.371428v97.52381H263.314286zM263.314286 624.152381H438.857143v97.523809H263.314286z" /></svg>
                        </button>
                        <button onClick={() => handleDelete(item.id)} className="bg-red-600 p-1 rounded text-white">
                          <svg xmlns="http://www.w3.org/2000/svg" height="22" fill="white" stroke="currentColor" viewBox="0 0 32 32" id="delete">
                            <path d="M24.2,12.193,23.8,24.3a3.988,3.988,0,0,1-4,3.857H12.2a3.988,3.988,0,0,1-4-3.853L7.8,12.193a1,1,0,0,1,2-.066l.4,12.11a2,2,0,0,0,2,1.923h7.6a2,2,0,0,0,2-1.927l.4-12.106a1,1,0,0,1,2,.066Zm1.323-4.029a1,1,0,0,1-1,1H7.478a1,1,0,0,1,0-2h3.1a1.276,1.276,0,0,0,1.273-1.148,2.991,2.991,0,0,1,2.984-2.694h2.33a2.991,2.991,0,0,1,2.984,2.694,1.276,1.276,0,0,0,1.273,1.148h3.1A1,1,0,0,1,25.522,8.164Zm-11.936-1h4.828a3.3,3.3,0,0,1-.255-.944,1,1,0,0,0-.994-.9h-2.33a1,1,0,0,0-.994.9A3.3,3.3,0,0,1,13.586,7.164Zm1.007,15.151V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Zm4.814,0V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Z"></path>
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='flex justify-center items-center mt-20 '>
          <button className='text-3xl bg-transparent border-none flex mx-10' onClick={prepage} disabled={currentPage === 0}><MdOutlineKeyboardDoubleArrowLeft /></button>
          {
            numbers.map((n, i) => {
              return (
                <div className={`page-item ${currentPage === n ? 'active' : ' '}`} key={i}>
                  <button className='px-4 py-2 text-lg font-semibold bg-indigo-800 text-white border-none rounded-md' onClick={() => changeCpage(n)} >{n + 1}</button>
                </div>
              )
            })
          }
          <button className='text-3xl bg-none border-none flex mx-10' onClick={nextpage} disabled={currentPage + 1 >= npage}><MdOutlineKeyboardDoubleArrowRight /></button>
          <select onChange={(e) => setRecordpage(e.target.value)} className="p-2 border border-black rounded-md text-black text-base font-semibold">
            <option value={20}>Show 20</option>
            <option value={50}>Show 50</option>
            <option value={100}>Show 100</option>
          </select>
        </div>
        {open ?
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-md w-[450px]">
              {getdetails.map((view, index) => {
                return (
                  <div className='p-4' key={index}>
                    <p className="text-xl text-center font-bold mb-6">Candidate All Details</p>
                    <div className='flex justify-between'>
                      <label className='block text-gray-700 text-lg font-bold mb-2 font-medium'>Name :</label>
                      <p className='block text-gray-700 text-base font-normal font-bold'>{view.firstName} {view.lastName}</p>
                    </div>
                    <div className='flex justify-between'>
                      <label className='block text-gray-700 text-lg font-bold mb-2 font-medium'>Email :</label>
                      <p className='block text-gray-700 text-base font-normal font-bold'>{view.email}</p>
                    </div>
                    <div className='flex justify-between'>
                      <label className='block text-gray-700 text-lg font-bold mb-2 font-medium'>Phone no. :</label>
                      <p className='block text-gray-700 text-base font-normal font-bold'>{view.mobileNumber}</p>
                    </div>
                    <div className='flex justify-between'>
                      <label className='block text-gray-700 text-lg font-bold mb-2 font-medium'>JobType :</label>
                      <p className='block text-gray-700 text-base font-normal font-bold'>{view.jobType}</p>
                    </div>
                    <div className='flex justify-between'>
                      <label className='block text-gray-700 text-lg font-bold mb-2 font-medium'>ApplyFor :</label>
                      <p className='block text-gray-700 text-base font-normal font-bold'>{view.applyFor}</p>
                    </div>
                    <div className='flex justify-between'>
                      <label className='block text-gray-700 text-lg font-bold mb-2 font-medium'>Reference by :</label>
                      <p className='block text-gray-700 text-base font-normal font-bold'>{view.reference_by ? view.reference_by : 'No Reference'}</p>
                    </div>
                    <div className='flex justify-between'>
                      <label className='block text-gray-700 text-lg font-bold mb-2 font-medium'>Experience :</label>
                      <p className='block text-gray-700 text-base font-normal font-bold'>{view.experience_year ? view.experience_year : 'No Experience'}</p>
                    </div>
                    <SecButton text='Close X' onClick={() => setOpen(false)} className='mt-4' />
                  </div>
                );
              })}
            </div>
          </div> : null}
        <ToastContainer />
      </Layout >
    </>
  );
};

export default EmployeeData;
