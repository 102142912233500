import React, { useEffect, useState } from "react";
// import "./testdata.css";
import { TfiUser } from "react-icons/tfi";
import { PiFileTextDuotone } from "react-icons/pi";
import { TbUserQuestion } from "react-icons/tb";
import { FaArrowUpRightDots } from "react-icons/fa6";
import axios from "axios";
import Layout from "../layout";
import LoaderSpiner from "../loader";
import Notification from "../notification";

function Testdata() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dashboardData, setDashboardData] = useState([])
  const [DashboardTestData, setDashboardTestData] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDashboardData();
    getDashboardTestdata();
  }, [])

  const getDashboardData = async () => {
    setLoading(true)
    await axios.get(`${apiUrl}/count/AllCount`).then((response) => {
      setDashboardData(response.data.data)
      setLoading(false)
    })
      .catch((error) => {
        console.error("Error fetching Technology data:", error);
        setLoading(false)
      });
  }

  const getDashboardTestdata = async () => {
    setLoading(true)
    await axios.get(`${apiUrl}/results/testCount`).then((response) => {
      setDashboardTestData(response.data)
      setLoading(false)
    })
      .catch((error) => {
        console.error("Error fetching Technology data:", error);
        setLoading(false)
      });
  }

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading..." desc="please don't refresh or back while data is loading" /> : null}
      <Layout setDropdown={false}>
        <div className="flex justify-between w-full flex-wrap gap-2 p-4">
          {/* <div className="dashboard_candidate_header">Dashboard</div> */}
          <div className="w-[65%] flex justify-between  rounded-lg gap-5 flex-wrap h-96">
            <div className="w-[48%] h-48 border rounded-lg bg-white px-10 pt-10 shadow-md transition-transform duration-300 hover:scale-105">
              <div className="flex justify-between items-center">
                <div className="mt-2">
                  <div className="text-2xl text-blue-700">
                    <p>
                      {/* Replace this with your icon component */}
                      <TfiUser />
                    </p>
                  </div>
                  <p className="text-xl mt-2">Candidate</p>
                </div>
                <div>
                  <p className="text-[50px] text-blue-700">{dashboardData.Candidates}</p>
                </div>
              </div>
            </div>
            {/* ===================================================Test */}
            <div className=" w-[48%] h-48 border rounded-lg bg-white px-10 pt-10 shadow-md transition-transform duration-300 hover:scale-105">
              <div className="flex justify-between align-center">
                <div className="mt-2">
                  <div className="text-2xl text-blue-700">
                    <p>
                      <PiFileTextDuotone />
                    </p>
                  </div>
                  <p className="text-xl mt-2">Test</p>
                </div>
                <div>
                  <p className="text-[50px] text-blue-700">{DashboardTestData.data}</p>
                </div>
              </div>
            </div>
            {/* ===================================================Question */}
            <div className=" w-[48%] h-48 border rounded-lg bg-white px-10 pt-10 shadow-md transition-transform duration-300 hover:scale-105">
              <div className="flex justify-between align-center">
                <div className="mt-2">
                  <div className="text-2xl text-blue-700">
                    <p>
                      <TbUserQuestion />
                    </p>
                  </div>
                  <p className="text-xl mt-2">Question</p>
                </div>
                <div >
                  <p className="text-[50px] text-blue-700">{dashboardData.Questions}</p>
                </div>
              </div>
            </div>
            {/* ===================================================Technology */}
            <div className=" w-[48%] h-48 border rounded-lg bg-white px-10 pt-10 shadow-md transition-transform duration-300 hover:scale-105">
              <div className="flex justify-between align-center">
                <div className="mt-2">
                  <div className="text-2xl text-blue-700">
                    <p>
                      <FaArrowUpRightDots />
                    </p>
                  </div>
                  <p className="text-xl mt-2">Technology</p>
                </div>
                <div>
                  <p className="text-[50px] text-blue-700">{dashboardData.Technologys}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[32%]">
            <Notification />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Testdata;