import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const LoginPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [adminname, setAdminname] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    if (adminname === "" || password === "") {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "please fill the all details",
      });
    } else {
      setLoading(true)
      axios.post(`${apiUrl}/admin/adminlogin`, { adminname, password })
        .then((response) => {
          // console.log('Login successful:', response.data.token);
          if (response.status == 200) {
            Swal.fire({
              title: "Form Login Successfully!",
              text: "You clicked the button.",
              icon: "success",
            });
            navigate("/admin/dashboard");
          }
          setLoading(false);
          setAdminname("");
          setPassword("");
          const userToken = {
            authToken: response.data.token,
          };
          localStorage.setItem("userToken", JSON.stringify(userToken));
        })
        .catch((error) => {
          console.error("Login failed:", error);
          setLoading(false);
          setError("!Login failed. Please check your credentials...");
        });
    }
  };

  return (
    <div className='flex flex-col lg:flex-row justify-center items-center w-full h-full md:h-screen md:w-full'>
      <div className='px-5 lg:px-10 py-7 lg:w-6/12 bg-[#eb951d] md:w-full h-full'>
        <div className='flex flex-col items-center justify-center gap-3 md:w-full'>
          <div className='flex flex-row items-center justify-center'>
            <div className='w-20'>
              <img className='rounded-full' src='logo192.png' alt="" />
            </div>
            <h2 className='text-white text-2xl pl-4'>ENS Enterprises Pvt Ltd</h2>
          </div>
          <p className='text-2xl text-white'>Your place to work Plan.<br /> Create. Control.</p>
          <div className=''>
            <img src="/images/illustration.png" alt="" />
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 flex flex-col items-center justify-center mt-4 sm:mt-8">
        <div className='shadow-lg border p-4 lg:p-7 rounded-lg bg-white'>
          <h1 className="text-center text-2xl font-bold font-sans text-black-900">Login</h1>
          <form className='mt-4'>
            <div className='flex flex-col'>
              <div className="m-2">
                <label className="text-gray-600">User Name</label>
                <input type='text' className='w-full border border-gray-500 rounded p-2 outline-none focus:shadow-outline' required value={adminname} onChange={(e) => setAdminname(e.target.value)} placeholder="enter user name" />
              </div>
              <div className="m-2">
                <label className="text-gray-600">Password</label>
                <input type="password" className='w-full border border-gray-500 rounded p-2 outline-none focus:shadow-outline' required value={password} onChange={(e) => setPassword(e.target.value)} autoComplete='off' placeholder="enter password" />
              </div>
              <div className='flex items-center justify-between'>
                <div className="m-2">
                  <input type="checkbox" name="" id="" />
                  <label className="text-gray-600 pl-2">Remember</label>
                </div>
                <div>
                  <label className="text-gray-600">Forget Password?</label>
                </div>
              </div>
              <div className='flex items-center justify-center'>
                <div className='flex flex-col ...'>
                  <button className='bg-[#eb951d] shadow-md py-2 px-4 my-3 rounded-md text-white' onClick={handleLogin} disabled={loading} >
                    {loading ? "Logging in..." : "Submit"}
                  </button>
                  <Link
                    to="/candidate-registration"
                    className="text-orange-400"
                  >
                    Candidate registration
                  </Link>
                </div>
              </div>
            </div>
          </form>
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
