import React, { useState, useEffect } from "react";
import axios from 'axios'
// import "./applyfor.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Active from './active-mark.png'
import Inactive from './cross-mark.png'
import Layout from "../layout";
import LoaderSpiner from "../loader";
import PreButton from "../preButton";
import SecButton from "../secButton";

const ApplyFor = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [techdata, setTechData] = useState([]);
  const [applyFor, setApplyFor] = useState("");
  const [status, setStatus] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [Iid, setIid] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)

  // --------
  const getTechdata = () => {
    setLoading(true)
    axios.get(`${apiUrl}/jobprofile/getall`)
      .then(response => {
        setTechData(response.data.data);
        setLoading(false)
      })
      .catch(error => {
        console.error('Error fetching Technology data:', error);
        setLoading(false)
      });
  };

  useEffect(() => {
    getTechdata();
  }, []);
  // -------------

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${apiUrl}/jobprofile/create`, { applyFor, status });
      if (response.status === 200) {
        toast.success('Add Technology Successfully', {
          position: toast.POSITION.TOP_RIGHT
        });
        setOpen(false);
        setApplyFor("");
        setStatus("");
        getTechdata();
      } else {
        toast.error('Data posting failed', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  }
  // -------

  function handleGetid(id) {
    setEditMode(true)
    setOpen(true)
    axios.get(`${apiUrl}/jobprofile/byId/${id}`, { applyFor, status })
      .then((response) => {
        const item = response.data.data;
        setApplyFor(item.applyFor);
        setStatus(item.status);
        setIid(item.id)
        getTechdata();
      })
      .catch((error) => {
        console.error("Error Get id data:", error);
      });
  }
  // -------

  function handleUpdate() {
    axios.put(`${apiUrl}/jobprofile/${Iid}`, { applyFor, status }).then((response) => {
      setOpen(false)
      getTechdata();
      toast.success('Update Technology Successfully', {
        position: toast.POSITION.TOP_RIGHT
      });
      setApplyFor("");
      setStatus("");
      setEditMode(false);
    }).catch((error) => {
      console.error("Error updating user data:", error);
    });
  }
  // -------------

  function handleDelete(id) {
    axios.delete(`${apiUrl}/jobprofile/${id}`).then((response) => {
      getTechdata();
      toast.success('Delete Technology Successfully', {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch((error) => {
      console.error("error deleting data:", error);
    });
  }

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading..." desc="please don't refresh or back while data is loading" /> : null}
      <Layout setDropdown={true
      }>
        <div>
          <div>
            <PreButton text='+ Add Job Profile'
              onClick={() => {
                setOpen(true);
                setEditMode(false);
                setApplyFor("");
                setStatus("");
              }} />
            {open ? <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-8 rounded-md w-96">
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Add Job Profile:</label>
                  <input
                    type="text"
                    value={applyFor}
                    onChange={(e) => setApplyFor(e.target.value)}
                    className="w-full border border-gray-300 p-2 rounded-md"
                    placeholder="Enter Profile Name"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Status:</label>
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="w-full border border-gray-300 p-2 rounded-md"
                  >
                    <option value="">--Select Status--</option>
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </select>
                </div>
                <div className="flex justify-between">
                  <SecButton text='Close' className="bg-red-600 hover:bg-red-400 hover:text-black " onClick={() => {
                    setOpen(false);
                    setApplyFor("");
                    setStatus("");
                    setEditMode(false)
                  }} />
                  {editMode ?
                    <SecButton text='Update' onClick={handleUpdate} /> :
                    <SecButton text='Add'
                      onClick={handleSubmit}
                    />}
                </div>
              </div>
            </div> : null}
          </div>
          <ToastContainer />
          <div className="flex flex-col mt-4">
            <div className="inline-block min-w-full py-2 align-middle p-2">
              <div className="border-b border-gray-200 h-[480px] overflow-y-scroll">
                <table className="min-w-full  divide-y divide-gray-200">
                  <thead className="bg-gray-50 sticky top-0">
                    <tr  >
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                      >
                        S.No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                      >
                        ApplyFor
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="bg-white divide-y divide-gray-200 cursor-pointer overflow-x-scroll max-h-[300px]"
                  >
                    {techdata.map((item, index) => {
                      return (
                        <tr key={index} className="transition-all hover:bg-gray-100 hover:shadow-lg text-center">
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {index + 1}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {item.applyFor}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900 flex justify-center">
                              <img
                                src={parseInt(item.status) ? Active : Inactive}
                                className="w-4"
                              />
                            </div>
                          </td>
                          <td className="px-6 py-4 text-sm whitespace-nowrap flex justify-center">
                            <div className="flex gap-2">
                              <button className="bg-green-600 p-1 rounded text-white" onClick={() => handleGetid(item.id)} >
                                <svg fill="none" height="22" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="22" xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                              </button>
                              <button onClick={() => handleDelete(item.id)} className="bg-red-600 p-1 rounded text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" height="22" fill="white" stroke="currentColor" viewBox="0 0 32 32" id="delete"><path d="M24.2,12.193,23.8,24.3a3.988,3.988,0,0,1-4,3.857H12.2a3.988,3.988,0,0,1-4-3.853L7.8,12.193a1,1,0,0,1,2-.066l.4,12.11a2,2,0,0,0,2,1.923h7.6a2,2,0,0,0,2-1.927l.4-12.106a1,1,0,0,1,2,.066Zm1.323-4.029a1,1,0,0,1-1,1H7.478a1,1,0,0,1,0-2h3.1a1.276,1.276,0,0,0,1.273-1.148,2.991,2.991,0,0,1,2.984-2.694h2.33a2.991,2.991,0,0,1,2.984,2.694,1.276,1.276,0,0,0,1.273,1.148h3.1A1,1,0,0,1,25.522,8.164Zm-11.936-1h4.828a3.3,3.3,0,0,1-.255-.944,1,1,0,0,0-.994-.9h-2.33a1,1,0,0,0-.994.9A3.3,3.3,0,0,1,13.586,7.164Zm1.007,15.151V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Zm4.814,0V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Z"></path></svg>
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ApplyFor;