import React from 'react'

function PreButton({ text, onClick, className, spanclassName1, spanclassName2 }) {

  const buttonClassName =
    `rounded-md px-3.5 py-2 m-1 overflow-hidden relative group cursor-pointer border-2 font-medium ml-2 border-indigo-600 text-indigo-600 text-white ${className}`

  const spanClassName1Styles =
    `absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-indigo-600 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease ${spanclassName1}`

  const spanClassName2Styles =
    `relative text-indigo-600 transition duration-300 group-hover:text-white ease${spanclassName2}`

  return (
    <div>
      <button className={buttonClassName} onClick={onClick}>
        <span className={spanClassName1Styles}></span>
        <span className={spanClassName2Styles}>{text}</span>
      </ button>
    </div>
  )
}

export default PreButton

