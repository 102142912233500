import React, { useState } from 'react'
import Layout from '../layout'
import axios from 'axios'
import SecButton from '../secButton';

const CreateEmployee = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [NewEmpData, SetNewEmpData] = useState({
        name: "",
        EmpId: "",
        email: "",
        mobilenumber: "",
        designation: "",
        empstatus: ""
    })

    const NewEmpChangeHandler = (e) => {
        const { name, value } = e.target;
        SetNewEmpData((prev) => {
            return { ...prev, [name]: value }
        })

    }
    const NewEmpformsubmit = async (e) => {
        e.preventDefault();
        const { name, EmpId, email, mobilenumber, designation, empstatus } = NewEmpData
        const payload = {
            name: name,
            emplyee_id: EmpId,
            emp_email: email,
            emp_phone: mobilenumber,
            about: designation,
            designation: 6,
            employee_status: empstatus
        }
        try {
            const res = await axios.post(`${apiUrl}/employeeurl/createemployee`, payload);
            console.log("res", res)
        } catch (error) {
            console.error('Error while creating employee', error);
        }


    };

    return (
        <Layout>
            <div className="container mx-auto">
                <h1 className='font-bold text-3xl text-center '>Create New Employee</h1>
                <form className="mx-auto mt-8 bg-white p-8 rounded-md shadow-md" style={{ width: "70%" }} onSubmit={(e) => {
                    NewEmpformsubmit(e)
                }}>
                    <div className="grid">
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                            <input
                                type="text"
                                className="w-full border border-gray-300 p-2 rounded-md"
                                placeholder="Enter Name"
                                name="name"
                                onChange={(e) => {
                                    NewEmpChangeHandler(e)
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Employee id</label>
                            <input
                                type="text"
                                className="w-full border border-gray-300 p-2 rounded-md"
                                placeholder="Enter Employee id"
                                name="EmpId"
                                onChange={(e) => {
                                    NewEmpChangeHandler(e)
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Email id</label>
                            <input
                                type="text"
                                className="w-full border border-gray-300 p-2 rounded-md"
                                placeholder="Enter Email id"
                                name="email"
                                onChange={(e) => {
                                    NewEmpChangeHandler(e)
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Mobile no</label>
                            <input
                                type="text"
                                className="w-full border border-gray-300 p-2 rounded-md"
                                placeholder="Enter Mobile number"
                                name="mobilenumber"
                                onChange={(e) => {
                                    NewEmpChangeHandler(e)
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Select Designation</label>
                            <select className="w-full border border-gray-300 p-2 rounded-md" name="designation" onChange={(e) => {
                                NewEmpChangeHandler(e)
                            }}>
                                <option value="">-- Select Designation --</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Select Status</label>
                            <select className="w-full border border-gray-300 p-2 rounded-md" name="empstatus" onChange={(e) => {
                                NewEmpChangeHandler(e)
                            }}>
                                <option value="">-- Select Status --</option>
                                <option value="0">0</option>
                                <option value="1">1</option>

                            </select>
                        </div>
                    </div>
                    <div className="mt-6 flex justify-end">
                        <SecButton text='Submit' />
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default CreateEmployee
