import React, { useEffect, useState } from 'react'
// import './ensemployee.css'
import axios from 'axios'
import Active from '../ApplyFor/active-mark.png'
import { toast } from 'react-toastify';
import Layout from '../layout'
import LoaderSpiner from '../loader';
import { Link } from "react-router-dom";
import PreButton from '../preButton';

function EnsEmployee() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [empalldata, setEmpAllData] = useState([])
    const [loading, setLoading] = useState(false);
    const [Searchdata, setSearchdata] = useState([]);
    const [pagecount, Setpagecount] = useState(1)
    const [paginatepagedata, Setpaginatepagedata] = useState(40)


    const getAllEmpData = async () => {
        setLoading(true)
        try {
            const res = await axios.get(`${apiUrl}/employeeurl/getemployees`);
            const resdata = res.data;
            setEmpAllData(resdata.data);
            setLoading(false)
        } catch (error) {
            console.error('ens all employee data', error);
            setLoading(false)
        }
    };

    useEffect(() => {
        getAllEmpData()
    }, [])

    function handleSearch() {
        axios.get(`${apiUrl}/employeeurl/searchemployee?by=${Searchdata}`)
            .then((response) => {
                const searchData = response.data;
                if (searchData?.data?.length > 0) {
                    Setpaginatepagedata(50)
                    Setpagecount(1)
                    setEmpAllData(searchData.data);
                }
                // console.log('Search data:', searchData);
            })
            .catch((error) => {
                toast.warning('No matching data found', {
                    position: toast.POSITION.TOP_RIGHT
                });
                console.error('Error fetching search data:', error);
            });
    }
    const IndexOfFirst = paginatepagedata * (pagecount - 1);
    const indexOfLast = IndexOfFirst + paginatepagedata;

    return (
        <>
            {loading === true ? <LoaderSpiner text="Loading..." desc="please don't refresh or back while data is loading" /> : null}
            <Layout setDropdown={false}>
                <div className="relative flex justify-between">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-5  pointer-events-none">
                        <svg
                            className="w-full h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                            />
                        </svg>
                    </div>
                    <input
                        type="search"
                        id="default-search"
                        className="block w-[250px] p-4 ps-10 text-sm ml-2 text-gray-900 border border-gray-300 rounded-lg focus:ring-none focus:border-[#EB5454] dark:border-gray-600 dark:placeholder-gray-400 dark:text-[#000] dark:focus:ring-[#EB5454] dark:focus:border-[#EB5454]"
                        placeholder="Search candidate by name ..."
                        value={Searchdata}
                        onChange={(e) => setSearchdata(e.target.value)}
                        onKeyUp={handleSearch} />

                    <Link to='/admin/create-employee'>
                        <PreButton text="+ Add Employee" />
                    </Link>
                </div>

                <div className="flex flex-col mt-4">
                    <div className="inline-block min-w-full py-2 align-middle p-2">
                        <div className="border-b border-gray-200 h-[480px] overflow-y-scroll">
                            <table className="min-w-full  divide-y divide-gray-200">
                                <thead className="bg-gray-50 sticky top-0">
                                    <tr  >
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                                        >
                                            Employee-ID
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                                        >
                                            E-Mail
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                                        >
                                            Phone no
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider  text-gray-500 uppercase"
                                        >
                                            Designation
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider text-gray-500 uppercase"
                                        >
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    className="bg-white divide-y divide-gray-200 cursor-pointer overflow-x-scroll max-h-[300px]"
                                >
                                    {empalldata.slice(IndexOfFirst, indexOfLast).map((item, index) => {
                                        return (
                                            <tr key={index} className="transition-all hover:bg-gray-100 hover:shadow-lg text-center">
                                                <td className="px-4 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900">
                                                        {item.emplyee_id}
                                                    </div>
                                                </td>
                                                <td className="px-4 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900">
                                                        {item.name}
                                                    </div>
                                                </td>
                                                <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                    {item.emp_email}
                                                </td>
                                                <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                    {item.emp_phone}
                                                </td>
                                                <td className="px-4 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900">
                                                        {item.designation}
                                                    </div>
                                                </td>
                                                <td className="px-4 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900 flex justify-center">
                                                        <img src={item.employee_status === 1 ? Active : item.employee_status} alt='' className='w-4' />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className='flex items-center justify-center gap-3'>
                                <button className=" bg-[#eb951d] hover:bg-[#fdc175] text-white font-bold py-2 px-4 rounded" onClick={() => {
                                    if (pagecount > 1) {
                                        Setpagecount(pagecount - 1)
                                    }
                                }}>
                                    Prev
                                </button>
                                <p>{pagecount}</p>
                                <button className="bg-[#eb951d] hover:bg-[#fdc175] text-white font-bold py-2 px-4 rounded" onClick={() => {

                                    if (indexOfLast < empalldata.length) {
                                        Setpagecount(pagecount + 1);
                                    }
                                }}>
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default EnsEmployee
