import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {

  return (
    <div className="w-100 bg-[#fff] border z-[99]">
      <div className="flex justify-between items-center p-2">
        <p className="">© 2024 ENS Enterprises</p>
        <div>
          <Link className='' to="/">Terms of Service</Link>
          <Link className='' to="/">Privacy Policy</Link>
        </div>
      </div>
    </div>
  )
}

export default Footer