import React, { useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
// import Dashboard_Reg_Form from "../components/DashboardRegForm";
import Testdata from "../components/Testdata";
import CandidateEdit from "../components/CandidateEdit";
import EmployeeData from "../components/EmpData";
import TechnologySection from "../components/TechnologySection";
// import EnsLoginForm from "../components/EnsLoginForm";
import QuestionSection from "../components/QuestionSection";
import ApplyFor from "../components/ApplyFor";
import QuestionSets from "../components/QuestionSets";
import Protected from "../components/Protected_Route";
import StartSession from "../components/StartSession/StartSession";
import Thankyoupage from "../components/Thankyoupage";
import NotFound from "../components/Notfound";
import GetAllQuestionSets from "../components/GetAllQuestionSets";
import GetQuestionSets from "../components/GetQuestionsets";
import GetTestDetails from "../components/GetTestDetails";
import EnsEmployee from "../components/EnsEmployee";
import CandidateRegistration from "../pages/candidate-registration";
import LoginPage from "../pages/login";
import CreateEmployee from "../components/EnsEmployee/Createemployee";

function MainRoute() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/candidate-registration" element={<CandidateRegistration />} />
        <Route path="/admin/dashboard"
          element={
            <Protected>
              <Testdata />
            </Protected>
          } />
        <Route path="/admin/editdata/:id" element={
          <Protected>
            <CandidateEdit />
          </Protected>
        } />
        <Route
          path="/admin/candidate_section"
          element={
            <Protected>
              <EmployeeData />
            </Protected>
          }
        />
        <Route
          path="/admin/employee"
          element={
            <Protected>
              <EnsEmployee />
            </Protected>
          }
        />
        <Route
          path="/admin/create-employee"
          element={
            <Protected>
              <CreateEmployee />
            </Protected>
          }
        />
        <Route
          path="/admin/technology_section"
          element={
            <Protected>
              <TechnologySection />
            </Protected>
          }
        />
        <Route
          path="/admin/question_section"
          element={
            <Protected>
              <QuestionSection />
            </Protected>
          }
        />
        <Route path="/admin/applyfor" element={
          <Protected>
            <ApplyFor />
          </Protected>
        }
        />
        <Route path="/admin/question_sets" element={
          <Protected>
            <QuestionSets />
          </Protected>
        }
        />
        <Route path="/getquestion_sets" element={
          <GetQuestionSets />
        }
        />
        <Route path="/interview_session" element={
          <StartSession />
        } />
        <Route path="/thankyou" element={
          <Thankyoupage />
        }
        />
        <Route path="/admin/get_all_questionsets" element={
          <Protected>
            <GetAllQuestionSets />
          </Protected>
        } />
        <Route path="/admin/test_details" element={
          <Protected>
            <GetTestDetails />
          </Protected>
        } />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default MainRoute;
