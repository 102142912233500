import React, { useEffect, useState } from "react";
import "./startSession.css";
import { NavLink, useLocation } from "react-router-dom";
import axios from "axios";

const StartSession = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [questioncount, setQuestionCount] = useState([])
  // console.log('session id question data', ApplyTechID)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const refer_ID = searchParams.get("refer");
  const ApplyTechID = searchParams.get("apply");

  const getQuestionCount = async () => {
    const mydata = {
      jobType_id: ApplyTechID,
      referTo_id: refer_ID
    }
    try {
      const response = await axios.post(`${apiUrl}/questionSets/getAllSetQues`, mydata)
      // const { data } = response.data
      setQuestionCount(response.data)
    } catch (error) {
      console.error('error fetching question count:', error)
    }
  }

  useEffect(() => {
    getQuestionCount();
  }, []);

  const handleActiveQuestion = async () => {
    const user_id = {
      id: id
    }
    try {
      const response = await axios.post(`${apiUrl}/user/addFlag`, user_id)
      console.log('flag response', response)
    } catch (error) {
      console.error('flag api error', error)
    }

  }

  return (
    <>
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-b from-blue-300 to-orange-300">
        <div className="wrapper flex flex-col items-center">
          <svg>
            <text x="50%" y="50%" textAnchor="middle" className="text-[20px] md:text-[24px] lg:text-[28px]">
              Session Start
            </text>
          </svg>
          <div className="text-center mt-4 md:mt-8 lg:mt-12">
            <p className="text-lg md:text-xl lg:text-2xl font-semibold pb-2">
              Total Question: {questioncount.totalQuestion}
            </p>
            <div className="pb-5">
              <NavLink to={`/getquestion_sets?id=${id}&refer=${refer_ID}&apply=${ApplyTechID}`}>
                <button className="border-none bg-blue-500 text-white px-6 md:px-8 lg:px-10 py-2 text-base md:text-lg lg:text-xl font-bold rounded-full" onClick={handleActiveQuestion}>
                  Start Exam
                </button>
              </NavLink>
            </div>
            <div className="pb-2">
              <NavLink to="/">
                <button className="border-none bg-blue-500 text-white px-6 md:px-8 lg:px-10 py-2 text-base md:text-lg lg:text-xl font-bold rounded-full">
                  Quit Exam
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartSession;
