import "./App.css";
import MainRoute from "./routes/index.js";

function App() {
  return (
    <>
      <MainRoute />
    </>
  );
}

export default App;
