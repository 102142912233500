
import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import Sidebar from "./sidebar";
import { useLocation } from "react-router-dom";

const Layout = ({ children, setDropdown }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 640);
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const isRouteAdmin = location.pathname.startsWith("/admin");
  const handlePopup = () => {
    setIsNotification(false)
    setIsDropdownOpen(false)
  }

  return (
    <div className="layoutContainer flex flex-col h-screen relative" >
      {isRouteAdmin ? <Header toggleSidebar={toggleSidebar} isSidebarOpen={showSidebar} setIsNotification={setIsNotification} isNotification={isNotification} isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} /> : <Header />}
      <div className="flex flex-row flex-1 relative">
        {isRouteAdmin && (
          <div
            className={`lg:w-1/6 bg-[#f1f1f1] ${isMobile
              ? `absolute top-0 left-0 w-full  ${showSidebar ? "block" : "hidden"
              }`
              : "block"
              }`}
          >
            <Sidebar setDropdown={setDropdown} />
          </div>
        )}
        <main className={`${isMobile && showSidebar ? "sss" : ""} w-full p-4 bg-[#F4F9FD]`} onClick={handlePopup}>
          {children}
        </main>
      </div>
      {isRouteAdmin ? <Footer className="mt-auto" /> : <Footer className="mt-auto" />}
    </div>
  );
};

export default Layout;