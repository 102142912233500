import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Protected({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      setUser(true);
    } else {
      if (location.pathname !== "/admin") {
        navigate("/");
      }
    }
  }, [location.pathname, navigate]);

  return (
    <div>
      {user === true || location.pathname === "/admin" ? <>{children}</> : null}
    </div>
  );
}
