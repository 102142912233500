import React, { useEffect, useState } from "react";
// import "./getquestionsets.css";
import axios from "axios";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import PreButton from "../preButton";
import LoaderSpiner from "../loader";

const GetQuestionSets = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [getQuestionSets, setgetQuestionSets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 3;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = getQuestionSets.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(getQuestionSets.length / recordsPerPage);
  const [selectedOptions, setSelectedOptions] = useState({
    questionIds: [],
    options: [],
  });
  const [loading, setLoading] = useState(false)
  const [AnswerOption, setAnswerOption] = useState("");
  const [set_id, setSet_ID] = useState(null);
  const startSerial = (currentPage - 1) * recordsPerPage + 1;
  // console.log("set id is >>>>", set_id);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const Reg_id = searchParams.get("id");
  const refer_ID = searchParams.get("refer");
  const ApplyTechID = searchParams.get("apply");
  // console.log('refer id is >>>>>', refer_ID)
  const navigate = useNavigate();
  const nextpage = () => {
    if (currentPage < totalPages && selectedOptions.questionIds.length > 0) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleGetQuestionData = async () => {
    setLoading(true)
    const mydata = {
      jobType_id: ApplyTechID,
      referTo_id: refer_ID,
    };
    try {
      const response = await axios.post(
        `${apiUrl}/questionSets/getAllSetQues`,
        mydata
      );
      const setsIds = response.data.data.map((question) => question.sets_id);
      setSet_ID(setsIds[0]);
      const { data } = response.data;
      setgetQuestionSets(data);
      setLoading(false)
      // console.log('get question data total ..>>>>', setsIds)
    } catch (error) {
      console.error("error", error);
      setLoading(false)
    }
  };

  useEffect(() => {
    handleGetQuestionData();
  }, []);

  const handleOptionChange = (questionId, option) => {
    setSelectedOptions((prev) => {
      const updatedQuestionIds = [...prev.questionIds];
      const updatedOptions = { ...prev.options };
      const questionIndex = updatedQuestionIds.indexOf(questionId);
      if (questionIndex !== -1) {
        updatedOptions[questionId] = option;
      } else {
        updatedQuestionIds.push(questionId);
        updatedOptions[questionId] = option;
      }
      setAnswerOption(updatedOptions);
      // console.log('Updated question IDs:', updatedQuestionIds);
      // console.log('All selected options:', updatedOptions);
      return {
        questionIds: updatedQuestionIds,
        options: updatedOptions,
      };
    });
  };

  const attendExamMail = async (data) => {
    // console.log("data: ", data);
    try {
      const response = await axios.post(
        `${apiUrl}/mailUrl/AttendExamMail`,
        data
      );
      // console.log("attendExamMail", response);
    } catch (error) {
      console.error("Errorrrrr", error);
    }
  };
  const getUserId = async (id) => {
    try {
      const response = await axios.get(
        `${apiUrl}/adminSideUser/getusersid/${id.user_id}`
      );
      const data = response.data.data;
      return data;
    } catch (error) {
      console.error("Errorrrrr", error);
      return null; // Handle the error as needed
    }
  };

  const getAllRightAnswer = async (id) => {
    // console.log('getAllRightAns line 119 >>>>>', id)
    try {
      const response = await axios.post(`${apiUrl}/results/getAllRightAns`, id);
      console.log("getAllRightAnswer", response);
    } catch (error) {
      console.error("Error in response", error);
    }
  };

  // ------------------------------------------
  const handlesubmitquestion = async () => {
    setLoading(true)
    const mydata = {
      sets_id: set_id,
      user_id: parseInt(Reg_id),
      data: AnswerOption,
    };
    // console.log("userData:mydata---------------->>>>>>>>>>", mydata);
    try {
      const response = await axios.post(`${apiUrl}/results/finelResult`, mydata);
      const [userData, rightAnswerData, answerData] = await Promise.all([
        getUserId(mydata),
        getAllRightAnswer(mydata),  /* this api is not working */
        getAnswerData(mydata),  /* this api is not working */
      ]);
      // console.log("userData:", userData);
      // console.log("rightAnswerData:", rightAnswerData);
      // console.log("answerData:", answerData);
      if (userData) {
        attendExamMail(userData);
      }
      if (response.status === 200) {
        navigate("/thankyou");
        setLoading(false)
      } else {
        alert("Question data submission failed");
      }
    } catch (error) {
      console.error("Error in handlesubmitquestion:", error);
      setLoading(false)
    }
  };
  // console.log("setExamDataWithMail", examDataWithMail);
  // ------------------------------

  const getAnswerData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/adminSideUser/getusersid/${Reg_id}`
      );
      // console.log("response >>>>>>>>>>>>>>>>>>>------------------:", response);
      // console.log("Reg_id:", Reg_id);
      const user_ids = {
        user_id: Reg_id,
      };
      const datas = {
        user_ids: user_ids.user_id,
      };
      // console.log("getuserid data is >>", datas);
      const response1 = await axios.post(`${apiUrl}/results/getAllRightAnsUsers`, datas);
      // console.log("getAllRightAns all answer data response1 >>>>>>>>>------------------", response1);
      // setgetAllAns(response1.data.userResults)
    } catch (error) {
      console.error("getAllRightAnsUsers error", error);
    }
  };

  return (
    <div className="bg-[#d8e5e485] w-full">
      {loading === true ? <LoaderSpiner text="Loading..." desc="please don't refresh or back while data is loading" /> : null}
      <div className="w-10/12 mx-auto py-6 mx-3">
        <h1 className="text-right pb-8 font-bold lg:text-3xl sm:text-sm mt-2 sm:mt-0 text-green-600">Total Question : &nbsp;{getQuestionSets.length}</h1>
        <h1 className="text-center pb-8 font-bold lg:text-3xl sm:text-sm mt-2 sm:mt-0">Welcome To Question Sets</h1>
        {records.map((item, index) => {
          const questionId = item?.ens_question?.id;
          const questionText = item?.ens_question?.question;
          return (
            <div key={index}>
              {/* <h1 className="ques_what">({startSerial + index}). {item.ens_question.question}</h1> */}
              <h1 className="mb-2">
                ({startSerial + index}).{" "}
                {questionText ?? "No question available"}
              </h1>
              <div className="flex flex-wrap justify-between pb-8 gap-2 w-full">
                <label className="flex items-center shadow-md w-full sm:w-5/12 my-2 sm:my-0 py-4 px-6 rounded-lg bg-white">
                  <input
                    type="radio"
                    name={`tag_${questionId}`}
                    value="A"
                    checked={selectedOptions.options[questionId] === "A"}
                    onChange={() => handleOptionChange(questionId, "A")}
                  />
                  <p className="ml-2">{item.ens_question?.option_A}</p>
                </label>
                <label className="flex items-center shadow-md w-full sm:w-5/12 my-2 sm:my-0 py-4 px-6 rounded-lg bg-white">
                  <input
                    type="radio"
                    name={`tag_${questionId}`}
                    value="B"
                    checked={selectedOptions.options[questionId] === "B"}
                    onChange={() => handleOptionChange(questionId, "B")}
                  />
                  <p className="ml-2">{item.ens_question?.option_B}</p>
                </label>
                <label className="flex items-center shadow-md w-full sm:w-5/12 my-2 sm:my-0 py-4 px-6 rounded-lg bg-white">
                  <input
                    type="radio"
                    name={`tag_${questionId}`}
                    value="C"
                    checked={selectedOptions.options[questionId] === "C"}
                    onChange={() => handleOptionChange(questionId, "C")}
                  />
                  <p className="ml-2">{item.ens_question?.option_C}</p>
                </label>
                <label className="flex items-center shadow-md w-full sm:w-5/12 my-2 sm:my-0 py-4 px-6 rounded-lg bg-white">
                  <input
                    type="radio"
                    name={`tag_${questionId}`}
                    value="D"
                    checked={selectedOptions.options[questionId] === "D"}
                    onChange={() => handleOptionChange(questionId, "D")}
                  />
                  <p className="ml-2">{item.ens_question?.option_D}</p>
                </label>
              </div>
            </div>
          );
        })}
        {currentPage < totalPages && (
          <div className="flex justify-end w-full" onClick={nextpage}>
            <button className="flex justify-end bg-green-500 px-4 text-white rounded-md text-lg focus:outline-none hover:bg-green-600 transition duration-500 ease-in-out">
              NEXT
              <BsArrowRight className="text-3xl pl-4" />
            </button>
          </div>
        )}
        {currentPage === totalPages && (
          <div className="text-end">
            <PreButton
              text="Submit Answer Sheet"
              onClick={() => {
                handlesubmitquestion();
                setCurrentPage(false);
              }}
            />
          </div>
        )}
      </div>
    </div >
  );
};

export default GetQuestionSets;
