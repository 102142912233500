import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./candidateEdit.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import Layout from "../layout";
import PreButton from "../preButton";
import SecButton from "../secButton";

function CandidateEdit() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { id: itemId } = useParams();
    console.log(itemId);
    const navigate = useNavigate();
    const [id, setId] = useState("");
    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [email, setemail] = useState("");
    const [mobileNumber, setmobileNumber] = useState("");

    useEffect(() => {
        axios.get(`${apiUrl}/adminSideUser/getusersid/${itemId}`)
            .then((response) => {
                const userData = response.data.data
                console.log(userData);
                setId(userData.id);
                setfirstName(userData.firstName);
                setlastName(userData.lastName);
                setemail(userData.email);
                setmobileNumber(userData.mobileNumber);
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    }, [itemId]);

    function handleSubmit() {
        const datafield = {
            id: itemId,
            firstName,
            lastName,
            email,
            mobileNumber,
        };
        axios.put(`${apiUrl}/adminSideUser/updateusers/${itemId}`, datafield)
            .then((response) => {
                // console.log("User data updated successfully:", response);
                navigate("/admin/candidate_section");
                toast.success('Successfully update data', {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch((error) => {
                console.error("Error updating user data:", error);
            });
    }


    return (
        <Layout>
            <div className="flex items-center justify-center h-full">
                <div className="w-9/12 bg-white rounded-xl overflow-hidden shadow-md">
                    <div className="p-8 ">
                        <div className="w-full">
                            <div className="mb-4">
                                <Link to='/admin/candidate_section'>
                                    <SecButton text="Back" />
                                </Link>
                            </div>
                            <div className="flex flex-wrap gap-4 w-full justify-between">
                                <div className="w-[47%]">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        className="w-full px-3 py-2 border rounded-md"
                                        onChange={(e) => setfirstName(e.target.value)}
                                        value={firstName}
                                    />
                                </div>
                                <div className="w-[47%]">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Last Name"
                                        className="w-full px-3 py-2 border rounded-md"
                                        onChange={(e) => setlastName(e.target.value)}
                                        value={lastName}
                                    />
                                </div>
                                <div className="w-[47%]">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                        E-Mail
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter Your Email Id"
                                        className="w-full px-3 py-2 border rounded-md"
                                        onChange={(e) => setemail(e.target.value)}
                                        value={email}
                                    />
                                </div>
                                <div className="w-[47%]">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumber">
                                        Phone Number
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter Your Phone Number"
                                        className="w-full px-3 py-2 border rounded-md"
                                        onChange={(e) => setmobileNumber(e.target.value)}
                                        value={mobileNumber}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <PreButton text="Submit" onClick={handleSubmit} className="mt-4" />
                            </div>
                        </div>

                        <ToastContainer />
                    </div>
                </div>
            </div>
        </Layout >
    );
}

export default CandidateEdit;
